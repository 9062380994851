import React, { useState, useEffect } from 'react';
import './Grafico.css';
import { Doughnut, Bar, defaults } from 'react-chartjs-2';
import 'chartjs-plugin-doughnutlabel';
import { Col, Row } from 'antd';
import { String } from '../../../utils/Strings';
defaults.global.legend.display = false;

const InformacionInteres = (props: any) => {

  const [compromiso, setCompromiso] = React.useState<number>();
  const [contribuciones, setContribuciones] = React.useState<number>();
  const [distribuciones, setDistribuciones] = React.useState<number>();

  useEffect(() => {
    configurarDataGrafico();
  }, [props.compromiso, props.contribuciones, props.distribuciones]);

  const configurarDataGrafico = () => {
    let altoDiv = 130;
    let mayorVal = 1;
     if (props.compromiso >= props.contribuciones) {
      if (props.compromiso >= props.distribuciones) {
        mayorVal = props.compromiso;  
      } else {
        mayorVal = props.distribuciones;
      }
    }
    else {
      mayorVal = props.contribuciones;
    }

    if (mayorVal === 0) {
      mayorVal = 1;
    } 
    let comp = (props.compromiso * 100) / mayorVal;
    let cont = (props.contribuciones * 100) / mayorVal;
    let dis = (props.distribuciones * 100) / mayorVal;

    comp = (altoDiv * comp) / 100;
    cont = (altoDiv * cont) / 100;
    dis = (altoDiv * dis) / 100;

    setCompromiso(comp);
    setContribuciones(cont);
    setDistribuciones(dis); 
  }

  function nFormatter(num: any) {
    let cap = ' ';
    let value = num;
    switch (true){
      case num >= 1000000000:
        value = (num / 1000000000).toFixed(2).replace(/\.0$/, '');
        cap = ' g';
        break;
      case num >= 1000000:
        value = (num / 1000000).toFixed(2).replace(/\.0$/, '');
        cap = ' m';
        break;
      case num >= 1000:
        value = (num / 1000).toFixed(2).replace(/\.0$/, '');
        cap = ' k';
        break;
      default:
        value = (num * 1).toFixed(2).replace(/\.0$/, '');
        break;
    }
        
    switch (localStorage["idioma"]){
      case "es": return value.replace('.',',') + cap;
      default: return value + cap;
    }
  }
  /*  const configurarDataGrafico = () => {
     let data = {
       labels: [
         'Compromiso',
         'Contribuciones',
         'Distribuciones'
       ],
       datasets: [{
         data: [2500000, 2000000, 106110.81],
         backgroundColor: [
           '#0056bf',
           '#9191a3',
           '#133263'
         ],
         hoverBackgroundColor: [
           '#0056bf',
           '#9191a3',
           '#133263'
         ]
       }]
     }
     setdata(data)
   } */


  return (
    <div className="contenedor-barras">
      <Col span={8} className="fila-barra">
        <div className="label-Total">€ {props.compromiso > 0 ? nFormatter(props.compromiso) : 0} </div>
        <div className="barra-grafico Compromiso" style={{ height: compromiso }}></div>
        <div className="label-Total">{props.traducciones.compromisoGrafico}</div>
      </Col>
      <Col span={8} className="fila-barra" style={{ left: 90 }}>
        <div className="label-Total">€ {props.contribuciones > 0 ? nFormatter(props.contribuciones) : 0} </div>
        <div className="barra-grafico Contribuciones" style={{ height: contribuciones }}></div>
        <div className="label-Total">{props.traducciones.contribuciones}</div>
      </Col>
      <Col span={8} className="fila-barra" style={{ left: 190 }}>
        <div className="label-Total">€ {props.distribuciones > 0 ? nFormatter(props.distribuciones) : 0} </div>
        <div className="barra-grafico Distribuciones" style={{ height: distribuciones }}></div>
        <div className="label-Total">Distrib.</div>
      </Col>
    </div>

    /*  <Bar
         data={data}
         width={100}
         height={250}
         options={{
             maintainAspectRatio: false,
             scales: {
               yAxes: [{
                 ticks: {
                   callback: function(value:any, index:any, values:any) {
                     return value.toLocaleString("es-ES",{style:"currency", currency:"EUR"});
                   }
                 }
               }]
             }
               plugins: {
                 doughnutlabel: {
                   labels: [
                     {
                       text: 'Total',
                       font: {
                         size: '40'
                       }
                     },
                     {
                       text: total,
                       font: {
                         size: '30'
                       },
                       color: 'grey'
                     }/* ,
                     {
                       text: '$100.000',
                       font: {
                         size: '30'
                       },
                       color: 'red'
                     },
                     {
                       text: '95%',
                       font: {
                         size: '45'
                       },
                     color: 'green'
                     } 
                   ]
                 }
               }		
         }}
     /> */
  );
};


export default InformacionInteres;
