import axios from 'axios';
import { Utils } from '../utils/Utils';
import { ILogin } from '../Modelos/inversores.models';
import { IRecuperarPassword } from '../Modelos/inversores.models';
import { any } from 'prop-types';


export class loginService {
    //Método para consultar eventos Mock
    public login(data: ILogin): Promise<any[]> {
        return new Promise((resolve, reject) => {
            try {
                Utils.getConfig().then((config: any) => {
                    axios.post(`${config.data[0].url_inversores}access/postaccess`, data).then((res:any) => {
                        if (res !== null) {
                            return resolve(res.data)
                        }
                        else {
                            return reject(null)
                        }
                    })
                })
            } catch (error) {
                return reject(error);
            }
        })
    }

    public recuperarPassword(data: IRecuperarPassword): Promise<any[]> {
        return new Promise((resolve, reject) => {
            try {
                Utils.getConfig().then((config: any) => {
                    axios.post(`${config.data[0].url_inversores}passreset/postsavetoken`, data).then((res:any) => {
                        if (res !== null) {
                            return resolve(res.data)
                        }
                        else {
                            return reject(null)
                        }
                    })
                })
            } catch (error) {
                return reject(error);
            }

        });
    }

    public actualizarContrasenia(data:any) : Promise<any[]> {
        return new Promise((resolve, reject) => {
            try {
                Utils.getConfig().then((config: any) => {
                    axios.post(`${config.data[0].url_inversores}users/putupdatepass`, data).then((res:any) => {
                        if (res !== null) {
                            return resolve(res.data)
                        }
                        else {
                            return reject(null)
                        }
                    })
                })
            } catch (error) {
                return reject(error);
            }

        });
    }

    public validarToken(email:any, token:any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            try {
                Utils.getConfig().then((config: any) => {
                    axios.get(`${config.data[0].url_inversores}passreset/gettoken?loginName=${email}&token=${token}`).then((res:any) => {
                        if (res !== null) {
                            return resolve(res.data)
                        }
                        else {
                            return reject(null)
                        }
                    })
                })
            } catch (error) {
                return reject(error);
            }

        });
    }
}
