import React, { useState, useEffect } from 'react';
import './DocumentosInversor.css';
import documentos from '../../../Assets/images/documentos.jpg';
import img from '../../../Assets/images/CheckInversor.png';
import { Select, DatePicker, Button, message, Spin } from 'antd';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { reportesService } from '../../../Servicios/reportes.service';
import moment from 'moment';
import { documentosService } from '../../../Servicios/documentos.service';
import { inversoresService } from '../../../Servicios/inversores.service';
import { MaintenanceService }  from '../../../Servicios/MaintenanceService';
import { Utils } from '../../../utils/Utils';
import { resolve } from 'dns';
import { GetIdList } from '../../../Modelos/posicion.model';
import ReportsAPI from '../../../Api/api.reports';
const _inversoresServices = new inversoresService();
const _DESCARGARDOCUMENTOS = new documentosService();
const _reportesServices = new reportesService();
const MySwal = withReactContent(Swal)
const { Option } = Select;

const DocumentosInversor = (props: any) => {

  const [tiporeporte, setTipoReporte] = useState([]);
  const [tipoReporteSeleccionado, settipoReporteSeleccionado]: any = useState([]);
  const [ListadoFechasDistribucion, setListadoFechasDistribucion] = useState([]);
  const [ListadoFechasCapitalCall, setListadoFechasCapitalCall] = useState([]);
  const [ListadoArchivosCartaCompromiso, setListadoArchivosCartaCompromiso] = useState([]);
  const [fechaInicio, setFechaInicio] = useState("2000-01-01");
  const [fechaFin, setFechaFin] = useState(moment(new Date()).format('YYYY/MM/DD'));
  const [fechaSeleccionada, setFechaSeleccionada] = useState("");
  const [investorSeleccionada, setInvestorSeleccionada] = useState("");
  const [loading, setLoading] = useState(false);
  const [ListadoFechasFiscal, setListadoFechasFiscal]: any = useState("");


  useEffect(() => {
    obtenerCartasCompromiso();
  }, [])


  useEffect(() => {

    obtenerTiposReportes();
    if (props.selectedRowKeys.length === 0) {
      settipoReporteSeleccionado([]);
    }
    //obtenerDatosInversores(props.fondoSeleccionado, localStorage.email);
    obtenerDatosInversores();
    obtenerFechasFiscal(props.fondoSeleccionado);
  }, [props.fondoSeleccionado, props.selectedRowKeys, props.idioma]);

  /*  const obtenerFechasFiscal = (fondo: string) => {
     if (fondo === 'Q-Energy III SCR' || fondo === 'Q-Energy III FCR') {
       let initYear = 2018;
       let arrayDates = [];
       for (let index = initYear; index <= new Date().getFullYear(); index++) {
         let date = `31/12/${index}`;
         arrayDates.push(date)
       }
       setListadoFechasFiscal(arrayDates)
     }
     else
       if
         (fondo === 'Q-Energy IV SCR' || fondo === 'Q-Energy IV FCR' || fondo === 'Q-Energy IV Tretimero') {
         let initYear = 2020;
         let arrayDates = [];
         for (let index = initYear; index <= new Date().getFullYear(); index++) {
           let date = `31/12/${index}`;
           arrayDates.push(date)
         }
         setListadoFechasFiscal(arrayDates)
       }
       else {
         let initYear = 2022;
         let arrayDates = [];
         for (let index = initYear; index <= new Date().getFullYear(); index++) {
           let date = `31/12/${index}`;
           arrayDates.push(date)
         }
         setListadoFechasFiscal(arrayDates)
       }
   }
  */
  const obtenerFechasFiscal = (fondo: string) => {
    if (fondo === 'Q-Energy III SCR' || fondo === 'Q-Energy III FCR') {
      let initYear = 2022;
      let arrayDates = [];
      for (let index = initYear; index < new Date().getFullYear(); index++) {
        let date = `31/12/${index}`;
        arrayDates.push(date)
      }
      setListadoFechasFiscal(arrayDates)
    }
    else
      if
        (fondo === 'Q-Energy IV SCR' || fondo === 'Q-Energy IV FCR' || fondo === 'Q-Energy IV Tretimero') {
        let initYear = 2022;
        let arrayDates = [];
        for (let index = initYear; index < new Date().getFullYear(); index++) {
          let date = `31/12/${index}`;
          arrayDates.push(date)
        }
        setListadoFechasFiscal(arrayDates)
      }
      else {
        let initYear = 2022;
        let arrayDates = [];
        for (let index = initYear; index < new Date().getFullYear(); index++) {
          let date = `31/12/${index}`;
          arrayDates.push(date)
        }
        setListadoFechasFiscal(arrayDates)
      }
  }
  const obtenerDatosInversores = () => {

    setListadoFechasCapitalCall([]);

    //_inversoresServices.obtenerInversores(IdPeip, fechaInicio, fechaFin, login).then((resolve: any) => {
    let ListadoFechasCapitalCall: any = [];
    let ListadoFechasDistribucion: any = [];
    props.listadoFechas.forEach((item: any, indexItem: number) => {
      //item.Institution.forEach((Institution: any, indexInstitution: number) => {
      //Institution.Movimiento.forEach((Movimiento: any, indexMovimiento: number) => {
      if (item.Type === 'CapitalCalls') {
        //Si es un solo inversor
        if (props.selectedRowKeys.length < 2) {
          let existe = ListadoFechasCapitalCall.map(function (e: any) { return e; })
            .indexOf(moment(item.Date.split('T')[0]).format('DD/MM/YYYY'));
          if (existe == -1) {
            props.selectedRowKeys.map((llave: any) => {
              if (parseInt(llave) == item.IdInvestor && item.Amount > 0) {
                ListadoFechasCapitalCall.push(moment(item.Date.split('T')[0]).format('DD/MM/YYYY'));
              }
            });
          }
        }
        //Si es mas de un inversor
        else {
          let existe = ListadoFechasCapitalCall.map(function (e: any) { return e; })
            .indexOf(moment(item.Date.split('T')[0]).format('DD/MM/YYYY'));
          if (existe == -1) {
            let arrayInvestorDates = props.selectedRowKeys.map((llave: any) => {
              return props.listadoFechas.filter((f: any) => f.IdInvestor == parseInt(llave) && f.Type == "CapitalCalls" && (moment(f.Date.split('T')[0]).format('DD/MM/YYYY') == (moment(item.Date.split('T')[0]).format('DD/MM/YYYY'))));
            });
            let exist = true;
            arrayInvestorDates.map((x: any, index: number) => {
              if (x.length === 0) {
                exist = false;
              }
              if (arrayInvestorDates.length === index + 1) {
                if (exist) {
                  ListadoFechasCapitalCall.push(moment(item.Date.split('T')[0]).format('DD/MM/YYYY'));
                }
              }
            });
          }
        }
      }
      if (item.Type === 'Distribution') {
        /* let existe = ListadoFechasDistribucion.map(function (e: any) { return e; })
          .indexOf(moment(item.Date.split('T')[0]).format('DD/MM/YYYY'));
        if (existe == -1) {
          ListadoFechasDistribucion.push(moment(item.Date.split('T')[0]).format('DD/MM/YYYY'));
        } */
        //Si es un solo inversor
        if (props.selectedRowKeys.length < 2) {
          let existe = ListadoFechasDistribucion.map(function (e: any) { return e; })
            .indexOf(moment(item.Date.split('T')[0]).format('DD/MM/YYYY'));
          if (existe == -1) {
            props.selectedRowKeys.map((llave: any) => {
              if (parseInt(llave) == item.IdInvestor) {
                ListadoFechasDistribucion.push(moment(item.Date.split('T')[0]).format('DD/MM/YYYY'));
              }
            });
          }
        }
        //Si es mas de un inversor
        else {
          let existe = ListadoFechasDistribucion.map(function (e: any) { return e; })
            .indexOf(moment(item.Date.split('T')[0]).format('DD/MM/YYYY'));
          if (existe == -1) {
            let arrayInvestorDates = props.selectedRowKeys.map((llave: any) => {
              return props.listadoFechas.filter((f: any) => f.IdInvestor == parseInt(llave) && f.Type == "Distribution" && (moment(f.Date.split('T')[0]).format('DD/MM/YYYY') == (moment(item.Date.split('T')[0]).format('DD/MM/YYYY'))));
            });
            let exist = true;
            arrayInvestorDates.map((x: any, index: number) => {
              if (x.length === 0) {
                exist = false;
              }
              if (arrayInvestorDates.length === index + 1) {
                if (exist) {
                  ListadoFechasDistribucion.push(moment(item.Date.split('T')[0]).format('DD/MM/YYYY'));
                }
              }
            });
          }
        }
      }
      if (indexItem + 1 && props.listadoFechas.length === indexItem + 1) {
        ListadoFechasCapitalCall.sort(function (a: any, b: any) {
          a = a.split('/').reverse().join('');
          b = b.split('/').reverse().join('');
          return a > b ? 1 : a < b ? -1 : 0;
          // return a.localeCompare(b);         // <-- alternative 
        });
        ListadoFechasDistribucion.sort(function (a: any, b: any) {
          a = a.split('/').reverse().join('');
          b = b.split('/').reverse().join('');
          return a > b ? 1 : a < b ? -1 : 0;
          // return a.localeCompare(b);         // <-- alternative 
        });
        //ListadoFechasCapitalCall = ListadoFechasCapitalCall.sort();
        //ListadoFechasDistribucion = ListadoFechasDistribucion.sort();
        setListadoFechasCapitalCall(ListadoFechasCapitalCall);
        setListadoFechasDistribucion(ListadoFechasDistribucion);
      }
      // });
      // });
    });
    //})
  }

  const obtenerTiposReportes = () => {

    _reportesServices.tiposReporte().then((resultado: any) => {
      if (props.idioma === "en") {
        props.setTextoAnuncio(resultado.filter((x: any) => x.Name === "Anuncio")[0].EnglishDescription);
      }
      else {
        props.setTextoAnuncio(resultado.filter((x: any) => x.Name === "Anuncio")[0].Description);
      }
      let NewRes: any = []
      resultado.map((x: any, i: number) => {
        if (x.EnglishDescription != '' && props.idioma === "en") {
          x.Description = x.EnglishDescription
          NewRes.push(x);
        }

        if (x.Description != '' && props.idioma === "es") {
          NewRes.push(x);
        }
        if (i + 1 === resultado.length) {
          setTipoReporte(NewRes);
        }
      });
    })
  }

  const consultarReporte = () => {
    if (fechaSeleccionada === "") {
      message.warning("Debe seleccionar una fecha");
    }
    else {
      setLoading(true);
      if (tipoReporteSeleccionado.Name === "CertificadoParticipacion.rpt" || tipoReporteSeleccionado.Name === "SolicitudDesembolso.rpt" || tipoReporteSeleccionado.Name === "NotificacionDistribucion.rpt" || tipoReporteSeleccionado.Name === "CertificadoFiscal.rpt"
        || tipoReporteSeleccionado.Name === "EN_CertificadoParticipacion.rpt" || tipoReporteSeleccionado.Name === "EN_SolicitudDesembolso.rpt" || tipoReporteSeleccionado.Name === "EN_NotificacionDistribucion.rpt" || tipoReporteSeleccionado.Name === "EN_CertificadoFiscal.rpt") {
        switch (tipoReporteSeleccionado.Name) {
          case "CertificadoParticipacion.rpt": generarCapitalAccount();
            break;
          case "EN_CertificadoParticipacion.rpt": generarCapitalAccount();
            break;
          case "SolicitudDesembolso.rpt": generarCapitalCall();
            break;
          case "EN_SolicitudDesembolso.rpt": generarCapitalCall();
            break;
          case "NotificacionDistribucion.rpt": GenerateDistributionNotice();
            break;
          case "EN_NotificacionDistribucion.rpt": GenerateDistributionNotice();
            break;
          case "CertificadoFiscal.rpt": GenerateReportFiscal();
            break;
          case "EN_CertificadoFiscal.rpt": GenerateReportFiscal();
            break;
        }
      }
      else {
        let fecha = fechaSeleccionada.split("/");
        _reportesServices.obtenerReportes(tipoReporteSeleccionado.Name, tipoReporteSeleccionado.Url, `InvId=${props.selectedRowKeys.toString()};Date=${fechaSeleccionada}`)
          .then((resolve: any) => {
            if (resolve.length > 0) {
              window.open(resolve[0], '_blank');
              window.open(resolve[1], '_blank');
            }
            else {
              window.open(resolve[0], '_blank');
            }
            setLoading(false);
          })
          .catch(err => {
            message.warning('El documento no está disponible, pongase en contacto con el administrador');
            setLoading(false);
          })
      }
    }
  }


  const generarCapitalAccount = () => {
    let fecha = fechaSeleccionada.split("/");
    let idioma = props.idioma;
    let investorArray = props.selectedRowKeys.map((x: string, i: number) => {
      return x;
    });
    let data: GetIdList = {
      investorIdList: investorArray,
      date: `${fecha[2]}-${fecha[1]}-${fecha[0]}`,
      language: idioma
    }
    ReportsAPI.GenerateCapitalAccount(data).then(res => {
      var blob = new Blob([res], { type: "application/pdf" });
      var link = window.URL.createObjectURL(blob);
      window.open(link);
      setLoading(false);
    });
  }

  const generarCapitalCall = () => {
    let fecha = fechaSeleccionada.split("/");
    let idioma = props.idioma;
    let investorArray = props.selectedRowKeys.map((x: string, i: number) => {
      return x;
    });
    let data: GetIdList = {
      investorIdList: investorArray,
      date: `${fecha[2]}-${fecha[1]}-${fecha[0]}`,
      language: idioma
    }
    ReportsAPI.GenerateCapitalCall(data).then(res => {
      var blob = new Blob([res], { type: "application/pdf" });
      var link = window.URL.createObjectURL(blob);
      window.open(link);
      setLoading(false);
    });
  }

  const GenerateDistributionNotice = () => {
    let fecha = fechaSeleccionada.split("/");
    let idioma = props.idioma;
    let investorArray = props.selectedRowKeys.map((x: string, i: number) => {
      return x;
    });
    let data: GetIdList = {
      investorIdList: investorArray,
      date: `${fecha[2]}-${fecha[1]}-${fecha[0]}`,
      language: idioma
    }
    ReportsAPI.GenerateDistributionNotice(data).then(res => {
      var blob = new Blob([res], { type: "application/pdf" });
      var link = window.URL.createObjectURL(blob);
      window.open(link);
      setLoading(false);
    });
  }

  const GenerateReportFiscal = () => {
    let fecha = fechaSeleccionada.split("/");
    let idioma = props.idioma;
    let investorArray = props.selectedRowKeys.map((x: string, i: number) => {
      return x;
    });
    let data: GetIdList = {
      investorIdList: investorArray,
      date: `${fecha[2]}-${fecha[1]}-${fecha[0]}`,
      language: idioma
    }
    ReportsAPI.GenerateReportFiscal(data).then(res => {
      var blob = new Blob([res], { type: "application/pdf" });
      var link = window.URL.createObjectURL(blob);
      window.open(link);
      setLoading(false);
    });
  }



  /*   const generarCapitalAccount = () => {
      let fecha = fechaSeleccionada.split("/");
      let fechaTemp = `${fecha[1]}/${fecha[0]}/${fecha[2]}`;
  
      _reportesServices.obtenerReportes(tipoReporteSeleccionado.Name, tipoReporteSeleccionado.Url, `InvId=${props.selectedRowKeys.toString()};Date=${fechaTemp};IdContact=${props.userData.IdContact}`)
        .then((resolve: any) => {
          window.open(resolve);
          setLoading(false);
        })
        .catch(err => {
          message.warning('El documento no está disponible, pongase en contacto con el administrador');
          setLoading(false);
        }) 
    } */

  const obtenerCartasCompromiso = () => {

    let urlSite = "/sites/QenergyPortalInversor/";
    let libraryname = "Contratos";
    _DESCARGARDOCUMENTOS.get(urlSite, libraryname).then((resolve: any) => {
      if (resolve !== null) {
        let arrayResult = resolve.d.results.map((x: any) => {
          let el: any = x;
          let id = x.FileLeafRef.split("-");
          el.idInvestor = id.length > 0 ? id[0] : -1;
          el.name = el.idInvestor !== -1 ? id[1] : "";
          el.date = id.length == 3 ? id[2].replaceAll("__", "-").split(".")[0].split(" ")[0] : "Inicial";
          return el;
        });
        setListadoArchivosCartaCompromiso(arrayResult);
      }
    });

    // _DESCARGARDOCUMENTOS.obtenerDocumentos(libraryname).then(json => console.log({ json }));
  }

  const fechaFormatoIngles = (date: string)=>{
    if (date=='Inicial') {
      return "Initial"
    }
    return moment(date).format("MM/DD/YYYY ");
  }

  const descargaCartaCompromiso = () => {

    if (!investorSeleccionada || investorSeleccionada == "") {
      let mES = 'Debe seleccionar un archivo';
      let mEN = 'You must select a file';
      message.warning(props.idioma === "en" ? mEN : mES);
      return
    }


    var errorMostrado = false;
    setLoading(true);
    props.selectedRowKeys.map((x: any, i: number) => {
      // var relativeUrl = `${tipoReporteSeleccionado.Url}${x}-Carta de compromiso.pdf`;
      var relativeUrl = investorSeleccionada;
      var nameFile = investorSeleccionada.split("/")[5];
      // _DESCARGARDOCUMENTOS.descargarDocumentos(relativeUrl, `${x}-Carta de compromiso.pdf`).then((resolve: any) => {
      _DESCARGARDOCUMENTOS.descargarDocumentos(relativeUrl, nameFile).then((resolve: any) => {
        window.open(resolve);
        if (props.selectedRowKeys.length == i + 1) {
          setLoading(false);
        }
      })
        .catch((e: any) => {
          if (!errorMostrado) {
            message.warning(`El documento no está disponible para algunos de los inversores seleccionados,
            pongase en contacto con el administrador`, 8);
            errorMostrado = true;
          }
          if (props.selectedRowKeys.length == i + 1) {
            setLoading(false);
          }
        }
        )
    });
    
    // window.open(`https://qep1.sharepoint.com/sites/QenergyPortalInversor/Contratos/Forms/AllItems.aspx?id=${investorSeleccionada}&parent=%2Fsites%2FQenergyPortalInversor%2FContratos%2FContratosInversion`);
  }

  const b64toBlob = (dataURI: any) => {
    let byteString = atob(dataURI.split(',')[1]);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var a = document.createElement("a");
    let blob = new Blob([ab], { type: 'application/pdf' });
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = "Reporte";
    a.click();
    window.URL.revokeObjectURL(url);
  }

  const mensajeSeleccionarInversores = () => {
    MySwal.fire({
      html: `<h3>Selecciona al menos un inversor</h3><img src='${img}' width='650'/>`,
      icon: 'info',
      width: 700
    }).then((res) => {
      props.setDefaultTabKey('1');
    })
  }

  function disabledDate(current: any) {
    return current && current > moment().endOf('day');
  }

  const handleChangeTipoReporte = (e: any) => {
    settipoReporteSeleccionado(tiporeporte.filter((x: any) => x.IdReport === e)[0]);
  }

  const IsAdmin = () => new MaintenanceService().obtenerValorGenericaMantenimiento({
    code: "email_admin",
  })

  const page = () => new MaintenanceService().obtenerValorGenericaMantenimiento({
    code: "page_mante",
  })

  async function handleCheckMaintenance ( buttonHandle:any ){
    setLoading(true);
    let token = localStorage.token;
    if (token !== "" && token !== undefined) {
      let mante = await page();
      if (mante.result.Value == 'true') {
        let admin = await IsAdmin();
        let userData = Utils.parseJwt(token);
        if(userData.unique_name != admin.result.Value){
          localStorage.clear();
          Utils.delete_cookie('token');
          window.location.href = "/maintenance";
        }else{
          setLoading(false);
          buttonHandle();
        }
      }else{
        setLoading(false);
        buttonHandle();
      }
    }
  }
  
  return (
    props.selectedRowKeys.length > 0 ?
      <div className="contenedor-documentos-inversor">
        <div className="titulo-seccion">{props.idioma === "en" ? "Investor documents" : "Documentos del inversor"}</div>
        <>
          <Select defaultValue={props.idioma === "en" ? "Document Type" : "Tipo de documento"} style={{ width: '40%' }} onChange={handleChangeTipoReporte}
            disabled={props.selectedRowKeys.length > 0 ? false : true}
          >
            {
              tiporeporte.map((x: any) =>
                x.Name != "Anuncio" ?
                  <Option value={x.IdReport}>{x.Description}</Option>
                  : null
              )
            }
          </Select>
          {tipoReporteSeleccionado ?
            <>
              {
                tipoReporteSeleccionado.Name === 'CartaCompromiso' ?
                  <>
                    <Select
                      defaultValue={props.idioma === "en" ? "Select File" : "Seleccione un archivo"}
                      style={{ margin: '10px', width: 260 }}
                      onChange={(val: any) => setInvestorSeleccionada(val)}>
                      {
                        ListadoArchivosCartaCompromiso.filter((x: any) => props.selectedRowKeys.includes(x.idInvestor))
                        .map((x: any) => <Option value={x.FileRef}>{`${props.idioma == "es" ? x.date : fechaFormatoIngles(x.date)}`}</Option>)
                      }
                    </Select>
                    <Button loading={loading} onClick={() => handleCheckMaintenance(descargaCartaCompromiso)} type="primary" style={{ margin: '10px' }} className='btnGenerarReporte boton'>{props.idioma === "en" ? "Open Document" : "Abrir documento"}</Button>
                  </>
                  : null
              }
              {
                tipoReporteSeleccionado.Name === 'CertificadoParticipacion.rpt' ?
                  <>
                    <DatePicker
                      style={{ margin: '10px', width: 160 }}
                      disabledDate={disabledDate}
                      format='DD/MM/YYYY'
                      onChange={(val: any) => setFechaSeleccionada(moment(val).format("DD/MM/YYYY"))} />
                    <Button loading={loading} className='btnGenerarReporte boton' style={{ margin: '10px' }} type="primary" onClick={() => handleCheckMaintenance(consultarReporte)}>{props.idioma === "en" ? "Generate report" : "Generar"}</Button>
                  </>
                  : null
              }

              {
                tipoReporteSeleccionado.Name === 'EN_CertificadoParticipacion.rpt' ?
                  <>
                    <DatePicker
                      style={{ margin: '10px', width: 160 }}
                      disabledDate={disabledDate}
                      format='DD/MM/YYYY'
                      onChange={(val: any) => setFechaSeleccionada(moment(val).format("DD/MM/YYYY"))} />
                    <Button loading={loading} className='btnGenerarReporte boton' style={{ margin: '10px' }} type="primary" onClick={() => handleCheckMaintenance(consultarReporte)}>{props.idioma === "en" ? "Generate report" : "Generar"}</Button>
                  </>
                  : null
              }

              {
                tipoReporteSeleccionado.Name === 'SolicitudDesembolso.rpt' ?
                  ListadoFechasCapitalCall.length > 0 ?
                    <>
                      <Select defaultValue={props.idioma === "en" ? "Select date" : "Seleccione una fecha"} style={{ margin: '10px', width: 160 }} onChange={(val: any) => setFechaSeleccionada(val)}>
                        {
                          ListadoFechasCapitalCall.map((x: any) => <Option value={x}>{x}</Option>)
                        }
                      </Select>
                      <Button className='btnGenerarReporte boton' loading={loading} type="primary" style={{ margin: '10px' }} onClick={() => handleCheckMaintenance(consultarReporte)}>{props.idioma === "en" ? "Generate report" : "Generar"}</Button>
                    </>
                    :
                    <Spin tip="Cargando fechas" className="spiner-fecha" />
                  : null
              }
              {
                tipoReporteSeleccionado.Name === 'EN_SolicitudDesembolso.rpt' ?
                  ListadoFechasCapitalCall.length > 0 ?
                    <>
                      <Select defaultValue={props.idioma === "en" ? "Select date" : "Seleccione una fecha"} style={{ margin: '10px', width: 160 }} onChange={(val: any) => setFechaSeleccionada(val)}>
                        {
                          ListadoFechasCapitalCall.map((x: any) => <Option value={x}>{x}</Option>)
                        }
                      </Select>
                      <Button className='btnGenerarReporte boton' loading={loading} type="primary" style={{ margin: '10px' }} onClick={() => handleCheckMaintenance(consultarReporte)}>{props.idioma === "en" ? "Generate report" : "Generar"}</Button>
                    </>
                    :
                    <Spin tip="Cargando fechas" className="spiner-fecha" />
                  : null
              }
              {
                tipoReporteSeleccionado.Name === 'NotificacionDistribucion.rpt' ?
                  <>
                    <Select defaultValue={props.idioma === "en" ? "Select date" : "Seleccione una fecha"} style={{ margin: '10px', width: 160 }} onChange={(val: any) => setFechaSeleccionada(val)}>
                      {
                        ListadoFechasDistribucion.map((x: any) => <Option value={x}>{x}</Option>)
                      }
                    </Select>
                    <Button
                      className='btnGenerarReporte boton'
                      type="primary" style={{ margin: '10px' }}
                      onClick={() => handleCheckMaintenance(consultarReporte)}
                      loading={loading}
                    >
                      {props.idioma === "en" ? "Generate report" : "Generar"}
                    </Button>
                  </>
                  : null
              }
              {
                tipoReporteSeleccionado.Name === 'EN_NotificacionDistribucion.rpt' ?
                  <>
                    <Select defaultValue={props.idioma === "en" ? "Select date" : "Seleccione una fecha"} style={{ margin: '10px', width: 160 }} onChange={(val: any) => setFechaSeleccionada(val)}>
                      {
                        ListadoFechasDistribucion.map((x: any) => <Option value={x}>{x}</Option>)
                      }
                    </Select>
                    <Button
                      className='btnGenerarReporte boton'
                      type="primary" style={{ margin: '10px' }}
                      onClick={consultarReporte}
                      loading={loading}
                    >
                      {props.idioma === "en" ? "Generate report" : "Generar"}
                    </Button>
                  </>
                  : null
              }
              {
                tipoReporteSeleccionado.Name === 'CertificadoFiscal.rpt' ?
                  <>
                    <Select defaultValue={props.idioma === "en" ? "Select date" : "Seleccione una fecha"} style={{ margin: '10px', width: 160 }} onChange={(val: any) => setFechaSeleccionada(val)}>
                      {
                        ListadoFechasFiscal.map((x: any) => <Option value={x}>{x}</Option>)
                      }
                    </Select>
                    <Button className='btnGenerarReporte boton' loading={loading} type="primary" style={{ margin: '10px' }} onClick={consultarReporte}>{props.idioma === "en" ? "Generate report" : "Generar"}</Button>
                  </>
                  : null
              }

              {
                tipoReporteSeleccionado.Name === 'EN_CertificadoFiscal.rpt' ?
                  <>
                    <Select defaultValue={props.idioma === "en" ? "Select date" : "Seleccione una fecha"} style={{ margin: '10px', width: 160 }} onChange={(val: any) => setFechaSeleccionada(val)}>
                      {
                        ListadoFechasFiscal.map((x: any) => <Option value={x}>{x}</Option>)
                      }
                    </Select>
                    <Button className='btnGenerarReporte boton' loading={loading} type="primary" style={{ margin: '10px' }} onClick={consultarReporte}>{props.idioma === "en" ? "Generate report" : "Generar"}</Button>
                  </>
                  : null
              }
            </>
            : null
          }
        </>
      </div>
      :
      null

  );
};

export default DocumentosInversor;


