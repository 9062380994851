import React, { useState, useEffect } from 'react';
import { IVideosProps } from './IVideosProps';
import styles from './Videos.module.scss';
import { Row, Col, Tree } from 'antd';
import DocumentsDataRoomAPI from '../../../Api/api.documentsDataRoom';
import DirectoryTree from 'antd/lib/tree/DirectoryTree';

const Videos: React.SFC<IVideosProps> = (props) => {

  const [arrayVideos, setarrayVideos] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [datosVideos, setDatosVideos] = useState([]);
  const [dataSourceBackUp, setDataSourceBackUp] = useState([]);

  useEffect(() => {
    if(dataSourceBackUp.length > 0){
      selectFirstVideo();
    }
    else{
      obtenerListadoVideos();
    }
  }, [props.Idioma]);

  const obtenerListadoVideos = () => {
    setDataSourceBackUp([]);
    DocumentsDataRoomAPI.getDataRoomVideos("VideosDataRoom").then((json:any)=>{
      let arrayVideos_Es:any = [];
      let arrayVideos_En:any = [];
      
      json = json.sort(function (a: any, b: any) { return a.Orden - b.Orden })
      json.map((x:any)=>{
        let el:any = {}
        el.title = x.Title;
        el.key = x.Title;
        el.url = x.URL;
        el.order = x.Orden;
       if(x.Idioma === "Español")
       {
        let existe = arrayVideos_Es.map(function (e: any) { return e.title; }).indexOf(el.title);
          if(existe === -1)
          {
            arrayVideos_Es.push(el);  
          }
       }
       else{
        let existe = arrayVideos_En.map(function (e: any) { return e.title; }).indexOf(el.title);
        if(existe === -1)
        {
          arrayVideos_En.push(el);  
        }
       }
        
      })
      setDataSourceBackUp(json)
      setDataSource(arrayVideos_En);
      setDatosVideos(arrayVideos_Es);
      let arrayDocs: any = [];
      if(props.Idioma === 'es')
      {
        arrayDocs = json.filter((x: any) => x.Title == 'Conoce Qualitas');
      }
      else{
        arrayDocs = json.filter((x: any) => x.Title == 'Get to know Qualitas');
      }
      setarrayVideos(arrayDocs);
    })
    .catch(err=>console.log(err))
  }

  const obtenerUrlVideo = (folder:string) => {
    //setItemSeleccionado(folder)
    DocumentsDataRoomAPI.getVideo('QUALITAS.mp4', 'videos').then((json: any) => {
      let arrayVideosTemp: any = [];
      arrayVideosTemp.push(json.data);
      arrayVideosTemp.push(json.data);
      arrayVideosTemp.push(json.data);
      setarrayVideos(arrayVideosTemp);
    })
  }

  const onSelect = (keys: any) => {
    let arrayDocs: any = [];
    arrayDocs = dataSourceBackUp.filter((x: any) => x.Title == keys[0]);
    setarrayVideos(arrayDocs);
};

const selectFirstVideo = () => {
  let arrayDocs: any = [];
  if(props.Idioma === 'es')
  {
    arrayDocs = dataSourceBackUp.filter((x: any) => x.Title == 'Conoce Qualitas');
  }
  else{
    arrayDocs = dataSourceBackUp.filter((x: any) => x.Title == 'Get to know Qualitas');
  }
    setarrayVideos(arrayDocs);
}

  return (
    <div className={`${styles.videos}`}>
      <Row>
        <Col span={1}></Col>
        <Col span={4}>
          <Tree
              onSelect={onSelect}
              treeData={props.Idioma === 'es' ? datosVideos : dataSource}
          />
        </Col>
        <Col span={1} className="separador-tabla-documentos"></Col>
        <Col span={17}>
          <Row>
            {arrayVideos.map((item:any) =>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} 
              style={{textAlign:'center',marginBottom:10}}>
                <>
                {(item.Title === 'Investor day') && (item.Orden === 1 || item.Orden === 3) ?
                <div className={styles.VideoTitle}>Unfortunately, the below video is only available in original version.</div>
                : <div className={styles.VideoTitle}>&nbsp;</div>}
                <iframe 
                  src={item.URL}
                  allowFullScreen
                  width="90%" 
                  frameBorder={0}
                  height={240}
                />
                </>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Videos;
