//import * as React from 'react';
import React, { useState } from 'react';
import 'antd/dist/antd.css';
import './App.css';
import Marquee from './components/Marquee/Marquee';
import DocumentosInversor from './components/DocumentosInversor/Reportes/DocumentosInversor';
import Grafico from './components/InformacionInteres/Grafico/Grafico';
import Carrusel from './components/Carrusel/Carrousel';
import PrincipalHeader from './components/Header/Header';
import Documentos from './components/DocumentosInversor/Documentos/Documentos';
import TablaInversores from './components/TablaInversores/TablaInversores';
import { menuServices } from './Servicios/menu.service';
import { reportesService } from './Servicios/reportes.service';
import { powerBIService } from './Servicios/powerBI.service';
import 'typeface-playfair-display';
import { idiomas } from './Assets/idiomas';
import { Layout, Menu, Icon, Tabs, Button, Row, Col, Select, Modal } from 'antd';
import Search from 'antd/lib/input/Search';
import PowerBI from './Inversores/PowerBIReport';
import HeadBlueBar from './components/HeadBlueBar/HeadBlueBar';
import InvestorsTable from './components/InvestorsTable/InvestorsTable';
import { Utils } from './utils/Utils';
import { IuserData } from './Modelos/userData.models';
import PeipsAPI from './Api/api.peips';
import InvestorsGridAPI from './Api/api.Investors';
import { debug } from 'console';
import Construccion from './components/Construccion/construccion';
import AvisoLegal from './components/ModalAvisoLegal/ModalAvisolegal';
import legalAcceptanceAPI from './Servicios/legalAcceptance.Service';
import { ILegalAcceptance } from './Modelos/legalAcceptance.model';


const _reportesServices = new reportesService();
const _powerBIService = new powerBIService();
const { Content, Footer, Sider } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;

const _menuServices = new menuServices();

const App: React.FC = () => {

  const [defaultTabKey, setDefaultTabKey] = React.useState('1');
  const [fondoSeleccionado, setfondoSeleccionado] = React.useState('Q-Energy III SCR');
  const [selectedRowKeys, setselectedRowKeys] = React.useState([]);
  const [ListadoPeips, setListadoPeips]: any = React.useState([]);
  const [TabSeleccionado, setTabSeleccionado] = React.useState("Inversores");
  const [visible, setVisible] = React.useState(false);
  const [InfoInversores, setInfoInversores] = React.useState([]);
  const [InfoGrupos, setInfoGrupos] = React.useState([]);
  const [textoAnuncio, setTextoAnuncio] = React.useState("");
  const [valorfiltro, setValorfiltro] = React.useState("");
  const [listadoFechas, setListadoFechas] = React.useState([]);
  //Datos grafico
  const [compromiso, setCompromiso] = React.useState();
  const [contribuciones, setContribuciones] = React.useState();
  const [distribuciones, setDistribuciones] = React.useState();

  const [idioma, setIdioma] = React.useState(window.navigator.language.split("-")[0]);
  const [traducciones, setTraducciones]: any = React.useState([]);

  const [filtroFondosBI, setFiltroFondosBI] = React.useState("");
  const [PowerBIEmbedUrl, setPowerBIEmbedUrl] = React.useState("https://app.powerbi.com/reportEmbed");
  const [PowerBIEmbedId, setPowerBIEmbedId] = React.useState("d3116d5a-4d80-4a6c-864f-8a5ba6cd1b8d");
  const [PowerBIToken, setPowerBIToken] = React.useState("");
  const [userData, setUserData]: any = React.useState<IuserData>();
  const [modalAvisoVisible, setModalAvisoVisible] = useState(false);
  const [ getDataGridInitial, setGetDataGridInitial] = useState(false);
  const [DataGridInitial, setDataGridInitial]: any = React.useState([]);

  const [newLegalAcceptance, setNewLegalAcceptance]: any = useState<ILegalAcceptance>({
    IdUser: 0,
  });

  React.useEffect(() => {
    obtenerTokenPowerBI();
    switch (idioma) {
      case 'es': setTraducciones(idiomas.es);
        break;
      case 'en': setTraducciones(idiomas.en);
        break;
    }

    if (userData && getDataGridInitial) {
      getDataGrid();
      if (userData) {
        getAcceptanceLegal(userData.IdContact)
      }
    }
  }, [fondoSeleccionado, TabSeleccionado, defaultTabKey, valorfiltro, idioma, userData]);

  React.useEffect(() => {
    if (userData) {
      setGetDataGridInitial(true);
    }
  }, [userData])

  React.useEffect(() => {
    document.title = traducciones.title;
  }, [traducciones])

  const obtenerTokenPowerBI = () => {
    _powerBIService.getBIToken().then((token: any) => {
      setPowerBIToken(token.AccessToken)
    })
  }

  const descargarExcel = () => {
    setVisible(true);
  }

  function callback(key: any) {
    setDefaultTabKey(key);
  }

  const handleClickFondo = (e: any) => {
    setselectedRowKeys([]);
    setfondoSeleccionado(e.key);
  };

  const versionExcelInferior = () => {
    setVisible(false);
    _reportesServices.descargarExcel('Resumen_De_Posicion_Fondo.xlsx', 'https://qep1.sharepoint.com/sites/QenergyPortalInversor/Reportes/Excel_2007/Resumen_De_Posicion_Fondo.xlsx', userData.IdContact);
  };

  const versionExcelSuperior = () => {
    debugger
    setVisible(false);
    _reportesServices.descargarExcel('Resumen_De_Posicion_Fondo.xlsx', 'https://qep1.sharepoint.com/sites/QenergyPortalInversor/Reportes/Excel/Resumen_De_Posicion_Fondo.xlsx', userData.IdContact);
  };

  const filtrar = (value: any) => {
    setValorfiltro(value);
  }

  const getDataGrid = () => {
    InvestorsGridAPI.get(userData.IdContact).then((json: any) => {
      setDataGridInitial(json);
      let arrayFondons: any = Array.from(new Set(json.map((a: any) => a.PeipVehicle)))
        .map(id => {
          return json.find((a: any) => a.PeipVehicle === id)
        })
      setFiltroFondosBI(arrayFondons);
      setListadoPeips(arrayFondons);

    }
    )
  }




  const getAcceptanceLegal = (idUser: number) => {
    legalAcceptanceAPI.get(idUser).then((resp: any) => {

      if (resp == null) {
        setModalAvisoVisible(true)
        saveAcceptLegal(idUser)
      }
    });
  }

  const saveAcceptLegal = (idUser: number) => {
    newLegalAcceptance.IdUser = idUser;
    newLegalAcceptance.Acceptance = true;
    legalAcceptanceAPI.postLegal(newLegalAcceptance).then(resp => {
      if (resp) {
        setNewLegalAcceptance({
          IdUser: 0,
        })
      }
    })
  }


  return (

    <div className="App">
      <Layout>
        <HeadBlueBar
          setIdioma={setIdioma}
          idioma={idioma}
          traducciones={traducciones}
          userData={userData}
          setUserData={setUserData}
        />
        <PrincipalHeader setTabSeleccionado={setTabSeleccionado} TabSeleccionado={TabSeleccionado} setIdioma={setIdioma} idioma={idioma} traducciones={traducciones}></PrincipalHeader>



        <AvisoLegal
          modalAvisoVisible={modalAvisoVisible}
          setModalAvisoVisible={setModalAvisoVisible}
          idioma={idioma}
        />

        {TabSeleccionado === "Analiza tu inversión" ?
          <div style={{
            marginTop: '10px',
            fontSize: '40px',
            textAlign: 'center',
            color: '#cfcfdc'
          }}
          >
            {/* <PowerBI
              PowerBIEmbedUrl={PowerBIEmbedUrl}
              PowerBIEmbedId={PowerBIEmbedId}
              PowerBIToken={PowerBIToken}
              filtroFondosBI={filtroFondosBI}
            /> */}
          </div>
          :
          <Layout>
            <Sider breakpoint="lg"
              collapsedWidth="0"
              onBreakpoint={(broken: any) => {
                console.log(broken);
              }}
              onCollapse={(collapsed: any, type: any) => {
                console.log(collapsed, type);
              }}
              style={{ background: '#fff' }}
              className="border-right"
              width={300}
            >
              <div className="border-bottom contenedor-menu-lateral">
                <div className="titulo-seccion">{traducciones.fondos}</div>
                {ListadoPeips.length > 0 ?
                  <Menu mode="inline" defaultSelectedKeys={["1"]} onClick={handleClickFondo} style={{ textAlign: 'left' }}>
                    {ListadoPeips.map((peip: any) =>
                      <Menu.Item key={peip.PeipVehicle}>
                        <Icon type="file-protect" />
                        <span className="nav-text">{peip.Category}</span>
                      </Menu.Item>
                    )}
                  </Menu>
                  : null
                }
              </div>

              <div className="border-bottom contenedor-menu-lateral">

              </div>

              <div className="contenedor-menu-lateral">
                <Tabs defaultActiveKey={defaultTabKey} activeKey={defaultTabKey} onChange={callback} style={{ textAlign: 'left' }}>
                  <TabPane tab={traducciones.tuInversion} key="1">
                    <Grafico
                      compromiso={compromiso}
                      contribuciones={contribuciones}
                      distribuciones={distribuciones}
                      traducciones={traducciones}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </Sider>
            <Layout style={{ padding: '24px' }}>
              <Content style={{ margin: '0' }}>
                <Row gutter={16}>
                  {TabSeleccionado === "Documentos" ?
                    <Col xs={0} sm={0} md={7} lg={5} xl={5}>

                      <div className="titulo-tab">{traducciones.documentosInversores}</div>
                    </Col>
                    : null
                  }
                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    {TabSeleccionado === "Inversores" ?
                      <DocumentosInversor selectedRowKeys={selectedRowKeys}
                        setDefaultTabKey={setDefaultTabKey}
                        fondoSeleccionado={fondoSeleccionado}
                        setTextoAnuncio={setTextoAnuncio}
                        idioma={idioma}
                        listadoFechas={listadoFechas}
                        userData={userData}
                      >
                      </DocumentosInversor>
                      : null
                    }
                  </Col>

                </Row>
                <Row>
                  <Col span={24}>
                    {TabSeleccionado === "Inversores" && userData !== undefined ?
                      <>
                        <InvestorsTable
                          userData={userData}
                          traducciones={traducciones}
                          fondoSeleccionado={fondoSeleccionado}
                          setselectedRowKeys={setselectedRowKeys}
                          setCompromiso={setCompromiso}
                          setContribuciones={setContribuciones}
                          setDistribuciones={setDistribuciones}
                          setInfoInversores={setInfoInversores}
                          InfoInversores={InfoInversores}
                          setListadoFechas={setListadoFechas}
                          setInfoGrupos={setInfoGrupos}
                          InfoGrupos={InfoGrupos}
                          setVisible={setVisible}
                          dataGridInitial={DataGridInitial}
                        />
                        {/*<TablaInversores selectedRowKeys={selectedRowKeys}
                        setselectedRowKeys={setselectedRowKeys}
                        fondoSeleccionado={fondoSeleccionado}
                        InfoInversores={InfoInversores}
                        setInfoInversores={setInfoInversores}
                        InfoInversoresBackUp={InfoInversoresBackUp}
                        setInfoInversoresBackUp={setInfoInversoresBackUp}
                        setCompromiso={setCompromiso}
                        setContribuciones={setContribuciones}
                        setDistribuciones={setDistribuciones}
                        valorfiltro={valorfiltro}
                        traducciones={traducciones}
                        setListadoFechas={setListadoFechas}
                  />*/}
                      </>
                      : null}
                    {TabSeleccionado === "Documentos" ?
                      <Documentos traducciones={traducciones} idioma={idioma} fondoSeleccionado={fondoSeleccionado} />
                      : null}
                  </Col>
                </Row>
              </Content>
            </Layout>
          </Layout>
        }
        <Footer>
          {/* <Marquee texto={textoAnuncio}></Marquee> */}
        </Footer>
      </Layout>


      <Modal
        visible={visible}
        title={traducciones.versionExcel}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" style={{ backgroundColor: '#9191a3', color: 'white' }} onClick={versionExcelInferior}>
            {traducciones.textSiete}
          </Button>,
          <Button key="submit" type="primary" style={{ backgroundColor: '#133263' }} onClick={versionExcelSuperior}>
            {traducciones.textDiez}
          </Button>,
        ]}
      >
        <p> {traducciones.seleccionVersion}</p>
      </Modal>



    </div>
  );
}

export default App;
