import React, { useState, useEffect } from 'react';
import './HomeDataRoom.css';
import { Row, Spin, Col, Tree, Table, Icon, message, Result } from 'antd';
import { FullscreenOutlined, FormOutlined } from '@ant-design/icons';
import { documentosService } from '../../../Servicios/documentos.service';
import DocumentsDataRoomAPI from '../../../Api/api.documentsDataRoom';
import ModalPDFViewer from '../ModalPDFViewer/ModalPDFViewer';
import HeadBlueBar from "../../HeadBlueBar/HeadBlueBar";
import PowerBI from '../../../Inversores/PowerBIReport';
import Videos from '../Videos/Videos';
import { idiomas } from '../../../Assets/idiomas';
import { powerBIService } from '../../../Servicios/powerBI.service';
import { Utils } from '../../../utils/Utils';
import { IuserData } from '../../../Modelos/userData.models';
import LogAPI from '../../../Api/api.log';
import { IaccessLog } from '../../../Modelos/AccessLog.model';
import PDFViewer from 'pdf-viewer-reactjs';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

const _powerBIService = new powerBIService();
const _OBTENERDOCUMENTOS = new documentosService();

const { TreeNode, DirectoryTree } = Tree;

const HomeDataRoom = ({ }) => {

    const [listaDocumentos, setListaDocumentos] = useState([]);
    const [resultadosDocumentos, setResultadosDocumentos] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tokenDocumento, setTokenDocumento] = useState("");
    const [modalVisorPDFVisible, setModalVisorPDFVisible] = useState(false);
    const [nombreDocumento, setNombreDocumento] = useState("");
    const [loadingDocumento, setLoadingDocumento] = useState(false);
    const [selectedSection, setSelectedSection] = useState("DataRoom");
    const [folderTree, setFolderTree] = useState([]);
    const [carpetas, setCarpetas]: any = useState();
    const [userData, setUserData]: any = React.useState<IuserData>();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    //Power BI
    const [filtroFondosBI, setFiltroFondosBI] = React.useState("");
    const [PowerBIEmbedUrl, setPowerBIEmbedUrl] = React.useState("https://app.powerbi.com/reportEmbed");
    const [PowerBIEmbedId, setPowerBIEmbedId] = React.useState("d3116d5a-4d80-4a6c-864f-8a5ba6cd1b8d");
    const [PowerBIToken, setPowerBIToken] = React.useState("");
    const [idioma, setIdioma] = React.useState(navigator.language.split("-")[0]);
    const [traducciones, setTraducciones]: any = React.useState([]);
    const [expandedKeys, setexpandedKeys]:any = useState([]);

    useEffect(() => {
        setListaDocumentos([]);
        setTokenDocumento("");
        obtenerDocumentos('DataRoom');
        obtenerTokenPowerBI();
        switch (idioma) {
            case 'es': setTraducciones(idiomas.es);
                break;
            case 'en': setTraducciones(idiomas.en);
                break;
        }
    }, [idioma]);

    const obtenerTokenPowerBI = () => {
        _powerBIService.getBIToken().then((token: any) => {
            setPowerBIToken(token.AccessToken)
        })
    }

    //Array de usuarios con permisos exclusivos
    //TODO: se debe refactorizar para administrar permisos dinámicamente
    /* const usersBancaPrivadaTemp = [
         "bankia@qualitasfunds.com",
         "renta4@qualitasfunds.com",
         "santander@qualitasfunds.com",
         "platinumclient@qualitasfunds.com"
     ]*/

    function onDocumentLoadSuccess(numPages: any) {
        setNumPages(numPages);
    }

    const obtenerDocumentos = (libraryPath: any) => {
        let arrayCarpetas: any = [];
        let arrayDocumentos: any = [];
        DocumentsDataRoomAPI.getAllSharepointDocuments(libraryPath).then((res: any) => {
            let resolve = res.data.filter((x: any) => x.Name.substr(x.Name.length - 4) === ".pdf" || x.Name.substr(x.Name.length - 4) === ".txt");
            arrayCarpetas = res.data.filter((x: any) => x.Name.substr(x.Name.length - 4) !== ".pdf");
            if (resolve !== null) {
                setResultadosDocumentos(resolve);
                var arrayTemp_En: any = [];
                var arrayTemp_Es: any = [];
                let token: any = Utils.obtenerCookie("token");
                let userData = Utils.parseJwt(token);
                setUserData(userData);
                resolve.map((url: any, i: number) => {
                    if (url.VisiblePara === null || url.VisiblePara.split(',').indexOf(userData.unique_name) !== -1) {
                        var carpeta = url.ServerRelativeURL.split("/").splice(4);
                        var documento: any = {};
                        documento.key = i;
                        documento.name = carpeta.pop();
                        if (carpeta.length == 1) {
                            documento.folder = carpeta[0];
                        } else {
                            documento.folder = carpeta[carpeta.length - 1];
                        }
                        if (documento.name.substr(documento.name.length - 4) === ".pdf") {
                            arrayDocumentos.push(documento);
                        }

                        if (carpeta[0] === "Ingles") {
                            var el: any = {};
                            let existe = arrayTemp_En.map(function (e: any) { return e.title; }).indexOf(carpeta[1]);
                            if (carpeta[1] === "How private equity works") {
                                el.title = `¿${carpeta[1]}?`;
                            }
                            else {
                                el.title = carpeta[1];
                            }
                            el.key = carpeta[1];
                            el.rol = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[1])[0].VisiblePara;
                            el.order = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[1])[0].Order;
                            existe == -1 && arrayTemp_En.push(el);

                            //Nivel 2
                            if (carpeta[2] !== undefined) {
                                let parentFolder = arrayTemp_En.map(function (e: any) { return e.title; }).indexOf(carpeta[1]);
                                existe = -1;
                                if (arrayTemp_En[parentFolder].children !== undefined) {
                                    existe = arrayTemp_En[parentFolder]
                                        .children.map(function (e: any) { return e.title; })
                                        .indexOf(carpeta[2]);
                                }
                                else {
                                    arrayTemp_En[parentFolder].children = [];
                                }
                                let children: any = {}
                                children.title = carpeta[2];
                                children.key = carpeta[2];
                                children.rol = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[2])[0].VisiblePara;
                                children.order = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[2])[0].Order;
                                if (existe == -1) arrayTemp_En[parentFolder].children.push(children);
                            }

                            //Nivel 3
                            if (carpeta[3] !== undefined) {
                                let parentFolder = arrayTemp_En.map(function (e: any) { return e.title; }).indexOf(carpeta[1]);
                                let parentSecondFolder = arrayTemp_En[parentFolder]
                                    .children.map(function (e: any) { return e.title; })
                                    .indexOf(carpeta[2]);
                                existe = -1;
                                if (arrayTemp_En[parentFolder].children[parentSecondFolder].children !== undefined) {
                                    existe = arrayTemp_En[parentFolder].children[parentSecondFolder].children
                                        .map(function (e: any) { return e.title; })
                                        .indexOf(carpeta[3]);
                                }
                                else {
                                    arrayTemp_En[parentFolder].children[parentSecondFolder].children = [];
                                }
                                let children: any = {}
                                children.title = carpeta[3];
                                children.key = carpeta[3];
                                children.order = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[3])[0].Order;
                                if (existe == -1) arrayTemp_En[parentFolder].children[parentSecondFolder].children.push(children);
                            }
                        }
                        else {
                            var el: any = {};
                            let existe = arrayTemp_Es.map(function (e: any) { return e.title; }).indexOf(carpeta[1]);
                            if (carpeta[1] === "Cómo funciona el private equity") {
                                el.title = `¿${carpeta[1]}?`;
                            }
                            else {
                                el.title = carpeta[1];
                            }
                            el.key = carpeta[1];
                            el.order = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[1])[0].Order;
                            el.rol = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[1])[0].VisiblePara;
                            existe == -1 && arrayTemp_Es.push(el);

                            //Nivel 2
                            if (carpeta[2] !== undefined) {
                                let parentFolder = arrayTemp_Es.map(function (e: any) { return e.title; }).indexOf(carpeta[1]);
                                existe = -1;
                                if (arrayTemp_Es[parentFolder].children !== undefined) {
                                    existe = arrayTemp_Es[parentFolder]
                                        .children.map(function (e: any) { return e.title; })
                                        .indexOf(carpeta[2]);
                                }
                                else {
                                    arrayTemp_Es[parentFolder].children = [];
                                }
                                let children: any = {}
                                children.title = carpeta[2];
                                children.key = carpeta[2];
                                children.rol = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[2])[0].VisiblePara;
                                children.order = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[2])[0].Order;
                                if (existe == -1) arrayTemp_Es[parentFolder].children.push(children);
                            }

                            //Nivel 3
                            if (carpeta[3] !== undefined) {
                                let parentFolder = arrayTemp_Es.map(function (e: any) { return e.title; }).indexOf(carpeta[1]);
                                let parentSecondFolder = arrayTemp_Es[parentFolder]
                                    .children.map(function (e: any) { return e.title; })
                                    .indexOf(carpeta[2]);
                                existe = -1;
                                if (arrayTemp_Es[parentFolder].children[parentSecondFolder].children !== undefined) {
                                    existe = arrayTemp_Es[parentFolder].children[parentSecondFolder].children
                                        .map(function (e: any) { return e.title; })
                                        .indexOf(carpeta[3]);
                                }
                                else {
                                    arrayTemp_Es[parentFolder].children[parentSecondFolder].children = [];
                                }
                                let children: any = {}
                                children.title = carpeta[3];
                                children.key = carpeta[3];
                                children.order = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[3])[0].Order;
                                if (existe == -1) arrayTemp_Es[parentFolder].children[parentSecondFolder].children.push(children);
                            }
                        }
                    }

                        if (i + 1 === resolve.length) {
                            arrayTemp_En = arrayTemp_En.sort(function (a: any, b: any) { return a.order - b.order });
                            arrayTemp_En.map((level1: any, index: number) => {
                                if (arrayTemp_En[index].children !== undefined) {
                                    arrayTemp_En[index].children = arrayTemp_En[index].children.sort(function (a: any, b: any) { return a.order - b.order })
                                    arrayTemp_En[index].children.map((x:any,i:number)=>{
                                        if (x.children !== undefined) {
                                            arrayTemp_En[index].children[i].children = x.children.sort(function (a: any, b: any) { return a.order - b.order })
                                        }
                                    })
                                }
                            });
                            arrayTemp_Es = arrayTemp_Es.sort(function (a: any, b: any) { return a.order - b.order });
                            arrayTemp_Es.map((level1: any, index: number) => {
                                if (arrayTemp_Es[index].children !== undefined) {
                                    arrayTemp_Es[index].children = arrayTemp_Es[index].children.sort(function (a: any, b: any) { return a.order - b.order })
                                    arrayTemp_Es[index].children.map((x:any,i:number)=>{
                                        if (x.children !== undefined) {
                                            arrayTemp_Es[index].children[i].children = x.children.sort(function (a: any, b: any) { return a.order - b.order })
                                        }
                                    })
                                }
                            });
                            
                            if (userData.roles.split("-").indexOf("DataRoom Full Access") === -1 ) {
                                // }

                                // if(usersBancaPrivadaTemp.indexOf(userData.unique_name) !== -1)
                                //{
                                arrayTemp_En.map((x:any)=>{

                                })
                                if (userData.roles.split("-").indexOf("Fondo 3") !== -1  && userData.roles.split("-").indexOf("Fondo 4") !== -1 )
                                {

                                }
                                else{
                                    if (userData.roles.split("-").indexOf("Fondo 3") !== -1) {
                                        arrayTemp_Es = arrayTemp_Es.filter((x: any)=>x.rol.split(',').indexOf("Fondo 3") !== -1);
                                        if(arrayTemp_Es[0].children !== undefined)
                                        {
                                            arrayTemp_Es[0].children = arrayTemp_Es[0].children.filter((x: any) => x.rol.split(',').indexOf("Fondo 3") !== -1);
                                        }
                                        arrayTemp_En = arrayTemp_En.filter((x: any)=>x.rol.split(',').indexOf("Fondo 3") !== -1);
                                        if(arrayTemp_En[0].children !== undefined)
                                        {
                                            arrayTemp_En[0].children = arrayTemp_En[0].children.filter((x: any) => x.rol.split(',').indexOf("Fondo 3") !== -1);
                                        }
                                    }
    
                                    if (userData.roles.split("-").indexOf("Fondo 4") !== -1) {
                                        arrayTemp_Es = arrayTemp_Es.filter((x: any)=>x.rol.split(',').indexOf("Fondo 4") !== -1);
                                        if(arrayTemp_Es[0].children !== undefined)
                                        {
                                            arrayTemp_Es[0].children = arrayTemp_Es[0].children.filter((x: any) => x.rol.split(',').indexOf("Fondo 4") !== -1);
                                        }
                                        arrayTemp_En = arrayTemp_En.filter((x: any)=>x.rol.split(',').indexOf("Fondo 4") !== -1);
                                        if(arrayTemp_En[0].children !== undefined)
                                        {
                                            arrayTemp_En[0].children = arrayTemp_En[0].children.filter((x: any) => x.rol.split(',').indexOf("Fondo 4") !== -1); 
                                        }
                                    }
                                }
                                
                                
                                setCarpetas(arrayTemp_Es);
                                setFolderTree(arrayTemp_En);
                                setDataSource(arrayDocumentos);
                            }
                            else {
                                setFolderTree(arrayTemp_En);
                                setCarpetas(arrayTemp_Es);
                                setDataSource(arrayDocumentos);
                            }
                            setLoading(false);

                            let arrayDocs: any = [];
                            arrayDocs = arrayDocumentos.filter((x: any) => x.folder == 'Documentación Legal del Fondo');
                            setListaDocumentos(arrayDocs);
                        }
                });
            }
            else {
                message.error('se presentó un problema al cargar los documentos.')
                setLoading(false);
            }
        }).catch((err: any) => console.log(err));
    }

    const descargaDocumentos = (name: any) => { 
        setLoadingDocumento(true);
        var ruta = resultadosDocumentos.filter((x: any) => x.Name == name);
        var relativeUrl = ruta[0]['ServerRelativeURL']
        const token = Utils.obtenerCookie("token");
        const userData = Utils.parseJwt(token);
        registrarAccesso(name, relativeUrl, 'Ver Documento', userData.unique_name);
        DocumentsDataRoomAPI.get(relativeUrl, name, userData.userName,idioma).then((resolve: any) => {
            setTokenDocumento(resolve.data);
            //setModalVisorPDFVisible(true);
            setNombreDocumento(name);
            setLoadingDocumento(false);

        })
            .catch((e: any) => console.log(e))
    }


    const onSelect = (keys: any) => {
        let arrayDocs: any = [];
        arrayDocs = dataSource.filter((x: any) => x.folder == keys[keys.length - 1]);
        setListaDocumentos(arrayDocs);
    };

    const onExpand = (keys: any, event: any) => {
        setexpandedKeys(["Documentos generales III"])
        let arrayDocs: any = [];
        arrayDocs = dataSource.filter((x: any) => x.folder == keys[keys.length - 1]);
        setListaDocumentos(arrayDocs);
    };

    const columns = [
        {
            title: traducciones.nombreDocumento,
            dataIndex: 'name',
            key: 'name',
            render: (text: any) => <a onClick={() => descargaDocumentos(text)}><Icon className="iconoPdf" type="file-pdf"></Icon>{text}</a>
        },
    ];

    const changeSelectedSection = (section: string) => {
        setSelectedSection(section);
    }

    const registrarAccesso = async (documentName: string, url: string, movement: string, email: string) => {
        let data: IaccessLog = {
            documentName: documentName,
            url: url,
            date: new Date(),
            movement: movement,
            email: email
        }
        LogAPI.AccessLog(data).then(json => {

        })
        .catch(err => console.log(err));
    }

    function disableRightclick(evt: any) {
        if (evt.button == 2) {
            alert(message);
            return false;
        }
    }

    return (
        <div>
            <HeadBlueBar
                setIdioma={setIdioma}
                idioma={idioma}
                traducciones={traducciones}
                userData={userData}
                setUserData={setUserData}
            />
            <Row className="contenedorHeader">
                <Col xs={0} sm={0} md={2} lg={2} xl={2}></Col>
                <Col xs={0} sm={0} md={2} lg={2} xl={2}>
                    <img src={require("../../../Assets/images/QualitasFunds.png")} />
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}></Col>
                <Col xs={24} sm={24} md={3} lg={3} xl={3}
                    className={`textMenu ${selectedSection === "DataRoom" && "textMenuActive"}`}
                    onClick={() => changeSelectedSection("DataRoom")}>
                    Data Room
                </Col>
                <Col xs={24} sm={24} md={2} lg={2} xl={2}
                    className={`textMenu ${selectedSection === "Videos" && "textMenuActive"}`}
                    onClick={() => changeSelectedSection("Videos")}>Videos</Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4}
                    className={`textMenu ${selectedSection === "analisis" && "textMenuActive"}`}
                    onClick={() => changeSelectedSection("analisis")}>
                    {traducciones.analiza}
                </Col>
            </Row>
            {selectedSection === "DataRoom" ?
                <Row>
                    {loading ?
                        <div className='contenedorSpiner'>
                            <Spin /> <br>
                            </br>
                            <h2>{traducciones.cargando}</h2>
                        </div>
                        : <Row  >
                            <Col span={24}>
                                <Col span={6} >
                                    <Row className="DataRoomTree">
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginLeft: 10 }}>
                                            {userData.roles.split("-").indexOf("DataRoom Full Access") !== -1 ?
                                                <Tree
                                                    onSelect={onSelect}
                                                    onExpand={onExpand}
                                                    treeData={idioma === 'en' ? folderTree : carpetas}
                                                />
                                                :
                                                <Tree
                                                    onSelect={onSelect}
                                                    onExpand={onExpand}
                                                    defaultExpandedKeys={idioma === 'en' ? ['Qualitas PE Program III (Fund Documents)'] : ['Qualitas PE Program III (Documentos del Fondo)']}
                                                    defaultSelectedKeys={idioma === 'en' ? ['Legal Documentation of the Fund'] : ['Documentación Legal del Fondo']}
                                                    treeData={idioma === 'en' ? folderTree : carpetas}
                                                />
                                            }
                                        </Col>
                                    </Row>

                                </Col>
                                <Col span={1} className="separador-tabla-documentos"></Col>
                                <Col span={8} className="contenedor-tabla-documentos">
                                    {listaDocumentos.length > 0 ?
                                        <Table
                                            pagination={{ pageSize: 7 }}
                                            dataSource={listaDocumentos}
                                            columns={columns}
                                            showHeader={true}
                                        />
                                        : null}
                                </Col>
                                <Col span={8} style={{ marginLeft: 10 }}>
                                    {console.log(userData)}
                                    {loadingDocumento ?
                                        <div className='contenedorSpiner'>
                                            <Spin /> <br>
                                            </br>
                                            <h2>{traducciones.cargando}</h2>
                                        </div>
                                        :
                                        tokenDocumento !== "" ?
                                            <>
                                                <div className="btnAmpliar" onClick={() => setModalVisorPDFVisible(true)}>{traducciones.ampliar}</div>
                                                {userData.roles.split("-").indexOf("Download Documents") !== -1 ?
                                                    <object onMouseDown={disableRightclick} data={`${tokenDocumento}#status=1&menubar=1&toolbar=1&location=1&resizable=1&scrollbars=1`} type="application/pdf" width="100%" height="500px">
                                                        <param name="src" value={`${tokenDocumento}#status=1&menubar=1&toolbar=1&location=1&resizable=1&scrollbars=1`} style={{ pointerEvents: 'none' }} />
                                                    </object>
                                                    :
                                                    <PDFViewer
                                                        document={{ url: tokenDocumento }}
                                                        protectContent={true}
                                                        navbarOnTop={true}
                                                        page={1}
                                                        scale={0.8}
                                                        hideRotation={true}
                                                    />
                                                }
                                                <ModalPDFViewer
                                                    setModalVisorPDFVisible={setModalVisorPDFVisible}
                                                    modalVisorPDFVisible={modalVisorPDFVisible}
                                                    tokenDocumento={tokenDocumento}
                                                    nombreDocumento={nombreDocumento}
                                                    fullAccess={userData.roles.split("-").indexOf("Download Documents") !== -1}
                                                />
                                            </>
                                            :
                                            <Result title={traducciones.SeleccionaDocumento} />
                                    }
                                </Col>
                            </Col>
                        </Row>
                    }
                </Row>
                :
                selectedSection === "Videos" ?
                    <Row>
                        <Videos Idioma={idioma} />
                    </Row>
                    :
                    <Row>
                        <PowerBI
                            PowerBIEmbedUrl={PowerBIEmbedUrl}
                            PowerBIEmbedId={PowerBIEmbedId}
                            PowerBIToken={PowerBIToken}
                            filtroFondosBI={filtroFondosBI}
                        />
                    </Row>

            }
        </div>
    );
};

export default HomeDataRoom;
