import axios from 'axios';

export class Utils {

  static URL_CONFIG_FILE: string = `${window.location.origin}/assets/config.js`

  public static getConfig(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {

        axios.get(this.URL_CONFIG_FILE)
          .then((data: any) => {
            if (data !== null) {
              return resolve(data);
            }
            else {
              return reject(null);
            }
          })
      } catch (error) {
        return reject(null);
      }
    })

  }

  public static crearCookie(clave:string, valor:string, exp:number) {
    var d = new Date(exp * 1000)
    var expires = "expires=" + d.toUTCString();
    let cookiepath = "/";
    document.cookie = clave + "=" + valor + "; " + expires +"; path=" + cookiepath;
  }

  public static obtenerCookie(clave:string) {
    var name = clave + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1);
      if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
  }

  public static comprobarCookie(clave:string) {
    var clave = Utils.obtenerCookie(clave);
    if (clave != "") {
      // La cookie existe.
    } else {
      // La cookie no existe.
    }
  }

  public static delete_cookie(name:string){
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  public static parseJwt(token: any) {
    if(token == "")
    {
      window.location.href = window.location.pathname === 'DataRoom' ? "/login/DataRoom" : "/login/PortalInversor";
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };
}