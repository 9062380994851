import axios from 'axios';
import { ILegalAcceptance } from '../Modelos/legalAcceptance.model';
import { Utils } from '../utils/Utils';

const legalAcceptanceAPI = {

    get(idUser: number) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].url_inversores}legal/get/${idUser}`)
                    .then(json => resolve(json.data))
                    .catch(err => reject(err));
            });
        });
    },


    postLegal(data: ILegalAcceptance) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].url_inversores}legal/PostLegal`, data)
                    .then(json => resolve(json))
                    .catch(err => reject(err));
            })
        });
    },


}

export default legalAcceptanceAPI;