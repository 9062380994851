import React, {Component} from 'react';
import Report from 'powerbi-report-component';
 
class PowerBI extends Component {
  constructor(props) {
    super(props);
    this.report = null; // to store the loaded report's object to perform operations like print, fullscreen etc..
  }

  setFilter = (filter) => this.report.setFilters([filter]).catch(function (errors) {
    console.log(errors);
  });

  handleDataSelected = (data) => {
    // will be called when some chart or data element in your report clicked
  }
 
  handleReportLoad = (report) => {
    // will be called when report loads:
    // - scripts and data received from server, visuals are rendered on the browser
    // - flickering Power BI logo stops appearing but report is not fully ready to be consumed
    /*const filter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "QUALITAS",
          column: "FondoQualitas"
        },
        operator: "In",
        values: this.props.filtroFondosBI
      };

      report.setFilters([filter]).catch(function (errors) {
        console.log(errors);
      });*/
 
    this.report = report; // get the object from callback and store it.(optional)
  }
 
  handleReportRender = (report) => {
    // will be called when report renders:
    // - visuals finish rendering
    // - report is fully visible and ready for consumption
 
    this.report = report; // get the object from callback and store it.(optional)
  }
 
  handlePageChange = (data) => {
    // will be called when pages in your report changes
  }
 
  handleTileClicked = (data) => {
    console.log('Data from tile', data);
  }
 
  render() {
    console.log(this.props.PowerBIEmbedUrl)
    const reportStyle = {
        height:800
        // style object for report component
    };
    const extraSettings = {
            filterPaneEnabled: false, //true
            navContentPaneEnabled: false, //true
            hideErrors: false // Use this *only* when you want to overide error experience i.e, use onError 
            // ... more custom settings
    };
    return (
    <div className="root">
        <Report
            embedType="report" // "dashboard"
            tokenType="Aad" // "Aad"
            accessToken={this.props.PowerBIToken} // accessToken goes here
            embedUrl={this.props.PowerBIEmbedUrl} // embedUrl goes here
            embedId={this.props.PowerBIEmbedId} // report or dashboard Id goes here
            pageName="" // set as current page of the report
            reportMode="view" // open report in a particular mode "view" or "edit".
            //dashboardId={dashboardId} // required when embedType is "tile"
            extraSettings={extraSettings}
            permissions="All" // View
            style={reportStyle}
            onLoad={this.handleReportLoad}
            onRender={this.handleReportRender}
            onSelectData={this.handleDataSelected}
            onPageChange={this.handlePageChange}
            onTileClicked={this.handleTileClicked}
        />
    </div>
    );
  }
}

export default PowerBI;
