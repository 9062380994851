export const getLanguage:any = {
    "es": () => "es",
    "en": () => "en",
    "de": () => "de",
    "login": () => "en",
}
export const dictionaryEmail:any ={
    "es": "Correo electrónico",
    "en": "Email",
    "de": "Email",
}
export const dictionaryPass:any ={
    "es": "Contraseña",
    "en": "Password",
    "de": "Passwort",
}
export const dictionaryForgotPass:any ={
    "es": "Recuperar contraseña",
    "en": "Forgot Password",
    "de": "Passwort wiederherstellen",
}
export const dictionaryLogin:any ={
    "es": "Iniciar sesión",
    "en": "Login",
    "de": "Einloggen",
}