import React, { useState, useEffect } from 'react';
import './Documentos.css';
import {Row,Col,Input,Select,DatePicker,Upload,Icon,Tooltip,Table,Tree, Spin, message,} from 'antd';
import { documentosService } from '../../../Servicios/documentos.service';
import DocumentsDataRoomAPI from '../../../Api/api.documentsDataRoom';
import { Utils } from '../../../utils/Utils';
const _OBTENERDOCUMENTOS = new documentosService();
const _DESCARGARDOCUMENTOS = new documentosService();

const { TreeNode, DirectoryTree } = Tree;

const Documentos = (props: any) => {

    const [listaDocumentos, setListaDocumentos] = useState([]);
    const [resultadosDocumentos, setResultadosDocumentos] = useState([]);
    const [carpetas, setCarpetas] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(true);
    const [defaultKey, setDefaultKey] = useState([]);
    const [folderTree, setFolderTree] = useState([]);
    const [loadingDocumento, setLoadingDocumento] = useState(false);
    const [tokenDocumento, setTokenDocumento] = useState("");
    const [nombreDocumento, setNombreDocumento] = useState("");
    const [folders, setFolders] = useState([]);

    useEffect(() => {
        if (dataSource.length > 0) {            
            setTokenDocumento("");
            filtrarDocumentos(folderTree, carpetas, resultadosDocumentos);
            setListaDocumentos([]);
            //setListaDocumentos(dataSource);
        }
        else {
            obtenerDocumentos('DocumentosInversores');
        }
    }, [props.idioma, props.fondoSeleccionado]);

    useEffect(() => { 
        resetFolders();
    },[dataSource,props.fondoSeleccionado])

    const resetFolders = () => {
        setLoading(true);
        if(dataSource.length > 0){            
            const _folders = props.idioma === 'en' ? clearEmptyFolders(folderTree) : clearEmptyFolders(carpetas);
            setFolders(_folders as any);
        }

        setLoading(false);
    }

    const clearEmptyFolders = (folder: Array<any>) => {
        const result: Array<any> = [];
        if(folder !== null && folder !== undefined){
            folder.forEach((x:any) => {
                const files = getFilesByKey(x.key);
                if(files.length > 0) result.push(x); 
            });
        }
        return result;
    }

    const getFilesByKey = (key: string) => {
        const result = dataSource.filter((x: any) => x.folder == key);
        return result;
    }

    const obtenerDocumentos = (libraryPath: any) => {
        let arrayCarpetas: any = [];
        let arrayDocumentos: any = [];
        DocumentsDataRoomAPI.getAllSharepointDocuments(libraryPath).then((res: any) => {
            let resolve = res.data.filter((x: any) => x.Name.substr(x.Name.length - 4) === ".pdf" ||
                x.Name.substr(x.Name.length - 4) === ".txt");
            arrayCarpetas = res.data.filter((x: any) => x.Name.substr(x.Name.length - 4) !== ".pdf");
            if (resolve !== null) {
                //setResultadosDocumentos(resolve);
                var arrayTemp_En: any = [];
                var arrayTemp_Es: any = [];
                let token: any = Utils.obtenerCookie("token");
                let userData = Utils.parseJwt(token);
                resolve.map((url: any, i: number) => {
                    if (url.VisiblePara === null || url.VisiblePara.split(',').indexOf(userData.unique_name) !== -1) {
                        var carpeta = url.ServerRelativeURL.split("/").splice(4);
                        var documento: any = {};
                        documento.key = i;
                        documento.ServerRelativeURL = url.ServerRelativeURL;
                        documento.name = carpeta.pop();
                        documento.fondo = url.Fondo;
                        if (carpeta.length == 1) {
                            documento.folder = carpeta[0];
                        } else {
                            documento.folder = carpeta[carpeta.length - 1];
                        }
                        if (url.Name.substr(url.Name.length - 4) !== ".txt") {
                            arrayDocumentos.push(documento);
                        }

                        if (carpeta[0] === "Ingles") {
                            var el: any = {};
                            let existe = arrayTemp_En.map(function (e: any) { return e.title; }).indexOf(carpeta[1]);
                            el.title = carpeta[1];
                            el.key = carpeta[1];
                            el.fondo = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[1])[0].Fondo;
                            el.order = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[1])[0].Order;
                            existe == -1 && arrayTemp_En.push(el);

                            //Nivel 2
                            if (carpeta[2] !== undefined) {
                                let parentFolder = arrayTemp_En.map(function (e: any) { return e.title; }).indexOf(carpeta[1]);
                                existe = -1;
                                if (arrayTemp_En[parentFolder].children !== undefined) {
                                    existe = arrayTemp_En[parentFolder]
                                        .children.map(function (e: any) { return e.title; })
                                        .indexOf(carpeta[2]);
                                }
                                else {
                                    arrayTemp_En[parentFolder].children = [];
                                }
                                let children: any = {}
                                children.title = carpeta[2];
                                children.key = carpeta[2];
                                children.order = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[2])[0].Order;
                                if (existe == -1) arrayTemp_En[parentFolder].children.push(children);
                            }

                            //Nivel 3
                            if (carpeta[3] !== undefined) {
                                let parentFolder = arrayTemp_En.map(function (e: any) { return e.title; }).indexOf(carpeta[1]);
                                let parentSecondFolder = arrayTemp_En[parentFolder]
                                    .children.map(function (e: any) { return e.title; })
                                    .indexOf(carpeta[2]);
                                existe = -1;
                                if (arrayTemp_En[parentFolder].children[parentSecondFolder].children !== undefined) {
                                    existe = arrayTemp_En[parentSecondFolder].children[parentSecondFolder].children
                                        .map(function (e: any) { return e.title; })
                                        .indexOf(carpeta[3]);
                                }
                                else {
                                    arrayTemp_En[parentFolder].children[parentSecondFolder].children = [];
                                }
                                let children: any = {}
                                children.title = carpeta[3];
                                children.key = carpeta[3];
                                children.order = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[3])[0].Order;
                                if (existe == -1) arrayTemp_En[parentFolder].children[parentSecondFolder].children.push(children);
                            }
                        }
                        else {
                            var el: any = {};
                            let existe = arrayTemp_Es.map(function (e: any) { return e.title; }).indexOf(carpeta[1]);
                            el.title = carpeta[1];
                            el.key = carpeta[1];
                            el.fondo = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[1])[0].Fondo;
                            el.order = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[1])[0].Order;
                            existe == -1 && arrayTemp_Es.push(el);

                            //Nivel 2
                            if (carpeta[2] !== undefined) {
                                let parentFolder = arrayTemp_Es.map(function (e: any) { return e.title; }).indexOf(carpeta[1]);
                                existe = -1;
                                if (arrayTemp_Es[parentFolder].children !== undefined) {
                                    existe = arrayTemp_Es[parentFolder]
                                        .children.map(function (e: any) { return e.title; })
                                        .indexOf(carpeta[2]);
                                }
                                else {
                                    arrayTemp_Es[parentFolder].children = [];
                                }
                                let children: any = {}
                                children.title = carpeta[2];
                                children.key = carpeta[2];
                                children.fondo = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[2])[0].Fondo;
                                children.order = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[2])[0].Order;
                                if (existe == -1) arrayTemp_Es[parentFolder].children.push(children);
                            }

                            //Nivel 3
                            if (carpeta[3] !== undefined) {
                                let parentFolder = arrayTemp_Es.map(function (e: any) { return e.title; }).indexOf(carpeta[1]);
                                let parentSecondFolder = arrayTemp_Es[parentFolder]
                                    .children.map(function (e: any) { return e.title; })
                                    .indexOf(carpeta[2]);
                                existe = -1;
                                if (arrayTemp_Es[parentFolder].children[parentSecondFolder].children !== undefined) {
                                    existe = arrayTemp_Es[parentFolder].children[parentSecondFolder].children
                                        .map(function (e: any) { return e.title; })
                                        .indexOf(carpeta[3]);
                                }
                                else {
                                    arrayTemp_Es[parentFolder].children[parentSecondFolder].children = [];
                                }
                                let children: any = {}
                                children.title = carpeta[3];
                                children.key = carpeta[3];
                                children.fondo = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[3])[0].Fondo;
                                children.order = arrayCarpetas.filter((folder: any) => folder.Name === carpeta[3])[0].Order;
                                if (existe == -1) arrayTemp_Es[parentFolder].children[parentSecondFolder].children.push(children);
                            }
                        }

                        if (i + 1 === resolve.length) {
                            arrayTemp_En = arrayTemp_En.sort(function (a: any, b: any) { return a.order - b.order });
                            arrayTemp_En.map((level1: any, index: number) => {
                                if (arrayTemp_En[index].children !== undefined) {
                                    arrayTemp_En[index].children = arrayTemp_En[index].children.sort(function (a: any, b: any) { return a.order - b.order })
                                }
                            });
                            arrayTemp_Es = arrayTemp_Es.sort(function (a: any, b: any) { return a.order - b.order });
                            arrayTemp_Es.map((level1: any, index: number) => {
                                if (arrayTemp_Es[index].children !== undefined) {
                                    arrayTemp_Es[index].children = arrayTemp_Es[index].children.sort(function (a: any, b: any) { return a.order - b.order })
                                }
                            });
                            setResultadosDocumentos(arrayDocumentos);
                            filtrarDocumentos(arrayTemp_En, arrayTemp_Es, arrayDocumentos)
                        }
                    }
                });
            }
            else {
                message.error('se presentó un problema al cargar los documentos.')
                setLoading(false);
            }
        }).catch((err: any) => console.log(err));
    }

    const filtrarDocumentos = (arrayTemp_En: any, arrayTemp_Es: any, arrayDocumentos: any) => {
        setFolderTree(arrayTemp_En);
        setCarpetas(arrayTemp_Es);
        setLoading(false);
        arrayDocumentos = arrayDocumentos.reverse();
        let arrayDocs = arrayDocumentos.filter((x: any) => x.fondo == (props.fondoSeleccionado))
        setDataSource(arrayDocs.reverse());
        setListaDocumentos(arrayDocs);
        arrayDocs = dataSource.filter((x: any) => x.folder == defaultKey[defaultKey.length - 1]);
        arrayDocs = arrayDocs.sort((a: any, b: any) => (a.orden > b.orden) ? 1 : ((b.orden > a.orden) ? -1 : 0));
        arrayDocs = arrayDocs.filter((x: any) => x.fondo == props.fondoSeleccionado);
        setListaDocumentos(arrayDocs);
    }

    const descargaDocumentos = (name: any) => {
        setLoadingDocumento(true);
        var ruta = resultadosDocumentos.filter((x: any) => x.name == name);
        var relativeUrl = ruta[0]['ServerRelativeURL']
        const token = Utils.obtenerCookie("token");
        const userData = Utils.parseJwt(token);
        DocumentsDataRoomAPI.get(relativeUrl, name, userData.userName,'es').then((resolve: any) => {
            if(  window.screen.width > 450){

            setTokenDocumento(resolve.data);
            //setModalVisorPDFVisible(true);
            setNombreDocumento(name);
            setLoadingDocumento(false);
     
             
            }
            else {
                window.open(resolve.data, "_blank")
            }
           
        })
            .catch((e: any) => console.log(e))
    }
    const onSelectKey = (keys: any) => {
        let arrayDocs: any = [];
        setDefaultKey(keys);
        arrayDocs = dataSource.filter((x: any) => x.folder == keys[keys.length - 1]);
        arrayDocs = arrayDocs.sort((a: any, b: any) => (a.orden > b.orden) ? 1 : ((b.orden > a.orden) ? -1 : 0));
        arrayDocs = arrayDocs.filter((x: any) => x.fondo == props.fondoSeleccionado);
        setListaDocumentos(arrayDocs);

    };
    const onExpandKey = (keys: any) => {
        let arrayDocs: any = [];
        setDefaultKey(keys);
        arrayDocs = dataSource.filter((x: any) => x.folder == keys[keys.length - 1]);
        arrayDocs = arrayDocs.sort((a: any, b: any) => (a.orden > b.orden) ? 1 : ((b.orden > a.orden) ? -1 : 0));
        arrayDocs = arrayDocs.filter((x: any) => x.fondo == props.fondoSeleccionado);
        setListaDocumentos(arrayDocs);
    };
    const onSelect = (keys: any) => {
        setDefaultKey(keys);
        let arrayDocs: any = [];
        arrayDocs = dataSource.filter((x: any) => x.folder == keys[keys.length - 1]);
        setListaDocumentos(arrayDocs);
    };
    const onExpand = (keys: any, event: any) => {
        let arrayDocs: any = [];
        arrayDocs = dataSource.filter((x: any) => x.folder == keys[keys.length - 1]);
        setListaDocumentos(arrayDocs);
    };
    const columns = [
        {
            title: props.traducciones.nombreDocumento,
            dataIndex: 'name',
            key: 'name',
            render: (text: any) => <a onClick={() => descargaDocumentos(text)}><Icon className="iconoPdf" type="file-pdf"></Icon>{text}</a>
        },
    ];

    return (
        <>
            <Row>
                {loading ?
                    <div className='contenedorSpiner'>
                        <Spin /> <br>
                        </br>
                        <h2>{props.traducciones.cargando}</h2>
                    </div>
                        : dataSource.length > 0 ? (
                            <Row>
                                <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Row className="DocumentosTree">
                                        <Col  xs={12} sm={12} md={6} lg={6} xl={6}className="contenedorTree">
                                            {carpetas ?
                                                <>
                                                    <DirectoryTree
                                                        multiple
                                                        defaultExpandAll
                                                        onSelect={onSelect}
                                                        onExpand={onExpand}
                                                        treeData={folders}
                                                    />
                                                </>
                                                : null}

                                        </Col>
                                        <Col xs={11} sm={11} md={6} lg={6} xl={6}className="contenedor-tabla-documentos">
                                            {listaDocumentos.length > 0 ?
                                                <Table
                                                    pagination={{ pageSize: 7 }}
                                                    dataSource={listaDocumentos}
                                                    columns={columns}
                                                    showHeader={true}
                                                />
                                                : null}
                                        </Col>
                                        <Col xs={24} sm={24} md={10} lg={10} xl={10} style={{ marginLeft: 10 }}>
                                            {/*console.log(tokenDocumento)*/}
                                            {loadingDocumento ?
                                                <div className='contenedorSpiner'>
                                                    <Spin /> <br>
                                                    </br>
                                                    <h2>{props.traducciones.cargando}</h2>
                                                </div>
                                                :
                                                tokenDocumento !== "" &&
                                                <object data={`${tokenDocumento}#status=1&menubar=1&toolbar=1&location=1&resizable=1&scrollbars=1`} type="application/pdf" width="100%" height="500px">
                                                    <param name="src" value={`${tokenDocumento}#status=1&menubar=1&toolbar=1&location=1&resizable=1&scrollbars=1`} />
                                                </object>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ) : <h2 className='no-documents'>{props.traducciones.noDocuments}</h2>
                }
            </Row>

        </>

    );
}

export default Documentos;