import React, { useEffect, useState } from 'react';
import { IModalAvisoProps } from './IModalAvisoProps';
import './ModalAvisoLegal.css';
import { Button, Col, Modal, Row } from 'antd';


const AvisoLegal: React.SFC<IModalAvisoProps> = (props) => {



    const CloseModal = () => {
        props.setModalAvisoVisible(false);
    }

    return (
        <div >

            {props.idioma == "es" ?
                <Modal
                    width="900px"
                    visible={props.modalAvisoVisible}
                    footer={[
                        <Button key="submit" onClick={CloseModal} style={{ background: "#4972B1", color: 'white' }}>
                            Aceptar
                        </Button>,
                    ]}
                >
                    <Row style={{ padding: '30px' }}>
                        <Row className="tituloModal">POLÍTICA DE PRIVACIDAD</Row>
                        <Row className="preguntasModal">
                            1. ¿Quién trata tus datos personales?

                        </Row>
                        <Row className='respuestaGeneral'>
                            El Responsable del Tratamiento (en adelante el Responsable) de tus
                            datos personales es:
                        </Row>
                        <Row className='respuestaAlineada'>
                            - Q-ENERGY PRIVATE EQUITY SGEIC, S.A.
                        </Row>
                        <Row className='respuestaAlineada'>
                            - C/ Jenner 3, planta 4ª, 28010, Madrid.
                        </Row>
                        <Row className='respuestaAlineada'>
                            - <a href="mailto:data.protection@qualitasequity.com">data.protection@qualitasequity.com</a>
                        </Row>
                        <Row className='respuestaGeneral'>
                            El  Responsable  ha  establecido  la  presente  Política  de  Privacidad,  la
                            cual recoge todos los aspectos referentes al tratamiento de tus datos
                            personales  a  través  de  nuestro  sitio  web    <a target="_blank" href="https://qenergy.com">https://qenergy.com</a>  (en
                            adelante el Sitio Web).
                        </Row>
                        <Row className='respuestaGeneral'>
                            Como Usuario e interesado, te informamos de la incorporación de tus
                            datos personales al tratamiento denominado “SITIO WEB” del Registro
                            de Actividades del Responsable.
                        </Row>
                        <Row className='respuestaGeneral'>
                            Los datos personales que te solicitamos a través del Sitio Web son, con
                            carácter  general,  obligatorios  (salvo  que  en  el  campo  requerido  se
                            especifique lo contrario) para cumplir con las finalidades establecidas.
                            Por  lo  tanto,  si  no  nos  los  facilitas  o  nos  los  facilitas  de  forma
                            incorrecta, no podremos atender tus solicitudes, sin perjuicio de que
                            pueda visualizarse libremente el contenido del Sitio Web.
                        </Row>
                        <Row className='respuestaGeneral'>
                            Para  obtener  más  información,  o  aclararte  cualquier  duda,  sobre  el
                            tratamiento de tus datos personales, puedes escribirnos a la dirección
                            postal  o  al  correo  electrónico  indicados  en  este  apartado.  Además,
                            puedes consultar la página web de la AEPD (Agencia Española de
                            Protección  de
                            Datos) <a target="_blank" href="​https://www.agpd.es/portalwebAGPD/index-ides-idphp.php​">​https://www.agpd.es/portalwebAGPD/index-ides-idphp.php</a>
                        </Row>


                        <Row className="preguntasModal">
                            2. ¿Con qué finalidad tratamos tus datos personales?
                        </Row>
                        <Row className='respuestaGeneral'>
                            Los  datos  personales  que  nos  facilitas  a  través  del  Sitio  Web,  son
                            tratados por el Responsable para las siguientes finalidades:
                        </Row>
                        <Row className='respuestaAlineada'>
                            - Gestionar tu registro y alta como Usuario para que accedas a tu
                            Área Privada, donde podrás conocer y descargar toda la
                            información relativa a tus inversiones.
                        </Row>
                        <Row className='respuestaAlineada'>
                            - Atender las consultas, solicitudes y dudas realizadas por el
                            Usuario a través del formulario de contacto del Sitio Web.
                        </Row>
                        <Row className='respuestaAlineada'>
                            - Gestionar el envío y recepción de curriculum vitae de posibles
                            candidatos para formar parte de procesos de selección internos
                            de la Entidad.
                        </Row>


                        <Row className="preguntasModal">
                            3. ¿Qué datos personales del Usuario tratamos?
                        </Row>
                        <Row className='respuestaGeneral'>
                            A través del Sitio Web recopilamos las siguientes categorías de datos
                            personales:
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Datos identificativos​:</b> Datos de representantes legales,
                            domicilio, nombre de usuario o cualquier otro identificador
                            similar si se trata de persona física, como apellidos, dni,
                            domicilio social, número de teléfono, correo electrónico.
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Datos de contacto:</b> ​domicilio, dirección de correo electrónico y
                            número de teléfono.
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Datos económicos: </b>     dirección de facturación, datos bancarios,
                            informes trimestrales, informes anuales, ingresos, balances, así
                            como cualquier dato personal relacionado con los productos y
                            servicios que hayas contratado.
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Datos de transacciones: </b>          datos de inversiones realizadas,
                            distribuciones recibidas por el Usuario, así como los detalles de
                            los productos y servicios que hayas contratado.
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Datos del perfil: </b>              nombre de Usuario y contraseña, historial de
                            inversiones realizados, además de todos los datos de perfil que
                            pudiéramos añadir.
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Datos técnicos:</b>      dirección del protocolo de Internet (IP), datos
                            de inicio de sesión, tipo y versión del navegador, configuración
                            de huso horario y ubicación, tipo y versión de los plug-ins del
                            navegador, sistema operativo y plataforma, así como otras
                            tecnologías que puedas utilizar en tus dispositivos para acceder
                            a esta página web.
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b> ● Datos de uso:</b>     información relevante sobre el uso que haces de
                            nuestra web.
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b> ● Datos de rastreo:  </b>   información que nosotros, o terceros,
                            recopilamos sobre ti mediante el uso de cookies y tecnologías
                            de rastreo similares.
                        </Row>
                        <Row className='respuestaGeneral'>
                            No recopilamos ningún dato personal perteneciente a categorías
                            especiales de datos.
                        </Row>

                        <Row className="preguntasModal">
                            4. ¿Cuál es la legitimación para el tratamiento de tus datos?
                        </Row>
                        <Row className='respuestaGeneral'>
                            El tratamiento de tus datos personales se fundamenta en las
                            siguientes bases legales:
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Ejecución del acuerdo de inversión con nosotros y la correcta
                                prestación de servicio. ​ </b>     Para gestionar el desarrollo y ejecución
                            del acuerdo de inversión y aquellos servicios que hayas
                            adquirido, para gestionar tu perfil de Usuario, así como para
                            atender tus consultas, sugerencias o solicitudes y para
                            comunicar tus datos personales a nuestros proveedores,
                            siempre que sea necesario para la prestación del servicio.
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Cumplimiento de obligaciones legales por parte del
                                Responsable.</b> ​Para la prevención de fraudes, comunicación de
                            tus datos a Administraciones Públicas y atender eventuales por
                            parte de terceros.
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Tu consentimiento expreso al marcar la correspondiente
                                casilla.</b> ​Para el envío de formulario de contacto, así como el
                            envío de Curriculum Vitae.
                        </Row>


                        <Row className="preguntasModal">
                            5. ¿A qué destinatarios se comunicarán tus datos personales?
                        </Row>
                        <Row className='respuestaGeneral'>
                            Tus datos personales podrán eventualmente ser comunicados a
                            nuestros proveedores externos, cuando dicha comunicación sea
                            necesaria para la prestación de servicios, en concreto:
                        </Row>
                        <Row className='respuestaAlineada'>
                            - Para los servicios de hosting, almacenamiento y procesamiento
                            de datos, a nuestros proveedores de estos servicios.
                        </Row>
                        <Row className='respuestaAlineada'>
                            - Para la gestión de los servicios relacionados con el
                            almacenamiento de datos en la nube.
                        </Row>
                        <Row className='respuestaGeneral'>
                            Además, tus datos personales podrán ser comunicados a
                            Administraciones Públicas, siempre que sea necesario para el
                            cumplimiento de obligaciones legales, o para atender eventuales
                            reclamaciones de terceros, por parte del Responsable.
                        </Row>
                        <Row className='respuestaGeneral'>
                            Dado que algunos de nuestros proveedores de servicios tienen
                            carácter global, es posible que el tratamiento de tus datos personales
                            por su parte impliquen transferencias internacionales de tus datos
                            fuera del Espacio Económico Europeo. En caso de realizar
                            transferencias internacionales nos aseguramos de que se realizan con
                            la seguridad que exige la legislación aplicable en materia de
                            protección de datos y siguiendo las indicaciones de la AEPD. Para más
                            información visita el siguiente enlace
                            <a target="_blank" href="https://www.aepd.es/es/derechos-y-deberes/cumple-tus-deberes/med
                        idas-de-cumplimiento/transferencias-internacionales"> https://www.aepd.es/es/derechos-y-deberes/cumple-tus-deberes/med
                                idas-de-cumplimiento/transferencias-internacionales</a>
                        </Row>

                        <Row className="preguntasModal">
                            6. ¿Cuánto tiempo conservaremos tus datos personales?
                        </Row>
                        <Row className='respuestaGeneral'>
                            Conservaremos tus datos como cliente el tiempo necesario para la
                            prestación del servicio. Posteriormente, sus datos personales
                            permanecerán bloqueados y sólo a disposición de las autoridades
                            competentes hasta que prescriban las responsabilidades que puedan
                            derivarse del tratamiento, siendo después eliminados de forma
                            segura, o bien anonimizados de forma que podamos seguir
                            utilizándolos con fines analíticos.
                        </Row>
                        <Row className='respuestaGeneral'>
                            Conservaremos tus datos como Usuario del Sitio Web hasta que
                            solicites la baja.
                        </Row>
                        <Row className='respuestaGeneral'>
                            Conservaremos tu curriculum vitae durante el tiempo necesario para
                            la realización de posibles procesos de selección. Con el fin de
                            mantenerlo actualizado, podremos solicitarte, siempre que te interese,
                            que nos remitas una versión actualizada del mismo, procediendo en
                            tal caso a eliminar la versión anterior y conservando únicamente la
                            versión actualizada.
                        </Row>

                        <Row className="preguntasModal">
                            7. ¿Qué derechos tienes?
                        </Row>
                        <Row className='respuestaGeneral'>
                            Puedes ejercitar los siguientes derechos, en cualquier momento y de
                            manera gratuita:
                        </Row>
                        <Row className='respuestaAlineada'>
                            - Obtener confirmación acerca de si estamos tratando datos
                            personales que te conciernen o no.
                        </Row>
                        <Row className='respuestaAlineada'>
                            - Acceder a tus datos personales.
                        </Row>
                        <Row className='respuestaAlineada'>
                            - Rectificar datos inexactos o incompletos.
                        </Row>
                        <Row className='respuestaAlineada'>
                            - Solicitar la supresión de tus datos.
                        </Row>
                        <Row className='respuestaAlineada'>
                            - Revocar el consentimiento para el tratamiento de tus datos, en
                            caso de que nos lo hayas otorgado, por ejemplo para el envío de
                            comunicaciones comerciales.
                        </Row>
                        <Row className='respuestaAlineada'>
                            - Solicitar la limitación del tratamiento de tus datos cuando se
                            cumpla alguna de las condiciones previstas en la normativa.
                        </Row>
                        <Row className='respuestaAlineada'>
                            - Solicitar la portabilidad de tus datos.
                        </Row>
                        <Row className='respuestaAlineada'>
                            - Oponerte al tratamiento de tus datos, en caso de que estemos
                            utilizando tus datos personales para remitirte comunicaciones
                            comerciales basadas en nuestro interés legítimo.
                        </Row>
                        <Row className='respuestaAlineada'>
                            - Interponer una reclamación relativa a la protección de datos
                            personales ante la Agencia Española de Protección de Datos
                            <a target="_blank" href="​www.aepd.es​">(www.aepd.es​)</a>
                        </Row>
                        <Row className='respuestaGeneral'>
                            Para ello, puedes ponerte en contacto con nosotros a través de un
                            escrito o correo electrónico, dirigidos a la dirección física o de email
                            indicadas en el apartado 1 de la presente Política de Privacidad,
                            adjuntando copia de tu documento de identidad, con la referencia
                            “Protección de Datos”.
                        </Row>
                        <Row className='respuestaGeneral'>
                            Como Usuario debes tener en cuenta que la seguridad de los sistemas
                            informáticos nunca es absoluta. Cuando se facilitan datos personales a
                            través de internet, dicha información puede ser recogida y tratada por
                            terceros no autorizados sin tu consentimiento y tratada por terceros
                            no autorizados.
                        </Row>

                        <Row className="preguntasModal">
                            8. Responsabilidad del Usuario
                        </Row>
                        <Row className='respuestaGeneral'>
                            Como Usuario, al facilitarnos datos personales a través de nuestros
                            formularios, declaras que eres mayor de edad y que los datos
                            personales que nos has facilitado son exactos, verdaderos, completos y
                            están actualizados. A estos efectos, como Usuario respondes de la
                            veracidad de todos los datos personales que nos facilitas y debes
                            mantener convenientemente actualizada la información facilitada, de
                            tal forma que responda a su situación real.
                        </Row>
                        <Row className='respuestaGeneral'>
                            Además, garantizas que has informado a los terceros de los que
                            facilites sus datos personales, en caso de hacerlo, de los aspectos
                            relativos al tratamiento de sus datos personales y contenidos en la
                            presente Política de Privacidad. Asimismo, garantizas que has
                            obtenido su autorización expresa para facilitarnos sus datos
                            personales con las finalidades indicadas.
                        </Row>

                        <Row className="preguntasModal">
                            9. Enlaces a sitios web de terceros.
                        </Row>
                        <Row className='respuestaGeneral'>
                            Nuestra web puede incluir enlaces a sitios web, plug-ins y aplicaciones
                            de terceros. Hacer clic en esos enlaces o habilitar esas conexiones
                            podría dar permiso a terceros para recopilar o compartir datos
                            relacionados contigo. El tratamiento de datos personales que los
                            titulares de estos sitios web realizan no están incluidos en el ámbito de
                            aplicación de la presente política de privacidad. Por tanto, no tenemos
                            ninguna responsabilidad o control sobre la información recopilada por
                            terceros ni sobre la protección y privacidad de la información que
                            usted facilita a través de dichos sitios web. Te recomendamos revisar
                            detenidamente las políticas de privacidad de todos los sitios web de
                            terceros para conocer de qué forma recopilan y comparten tu
                            información.
                        </Row>

                        <Row className="preguntasModal">
                            10. Medidas de seguridad
                        </Row>
                        <Row className='respuestaGeneral'>
                            Tratamos tus datos personales de forma confidencial y guardando el
                            deber de secreto respecto de los mismos, adoptando en todo
                            momento las medidas de índole técnica y organizativas necesarias
                            para garantizar la seguridad de tus datos y evitar así posibles
                            alteraciones, pérdidas, destrucción, tratamientos o accesos no
                            autorizados, según el estado de la técnica, la naturaleza de los datos
                            almacenados y del tratamiento de los mismos y los riesgos a los que
                            están expuestos.
                        </Row>
                        <Row className='respuestaGeneral'>
                            Asimismo, todos nuestros proveedores encargados del tratamiento
                            suscriben con nosotros el correspondiente contrato para asegurarnos,
                            en la medida de lo posible, de que éstos cumplan con la normativa
                            vigente en materia de protección de datos.
                        </Row>

                        <Row className="preguntasModal">
                            11. Actualización de la Política de privacidad
                        </Row>
                        <Row className='respuestaGeneral'>
                            Es probable que actualicemos la presente Política de Privacidad
                            debido a cambios normativos, recomendaciones de las autoridades
                            competentes o cambios en el tratamiento de tus datos personales.
                            Siempre que realicemos dichas actualizaciones encontrarás la última
                            versión de nuestra política de privacidad aquí.
                        </Row>
                    </Row>

                </Modal> :


                <Modal
                    width="900px"
                    visible={props.modalAvisoVisible}
                    footer={[
                        <Button key="submit" onClick={CloseModal} style={{ background: "#4972B1", color: 'white' }}>
                            Accept
                        </Button>,
                    ]}
                >
                    <Row style={{ padding: '30px' }}>
                        <Row className="tituloModal">PRIVACY POLICY</Row>
                        <Row className="preguntasModal">
                            1. Who processes your personal data?

                        </Row>
                        <Row className='respuestaGeneral'>
                            The Data Controller (hereinafter, the Controller) of your personal data is:
                        </Row>
                        <Row className='respuestaAlineada'>
                            - Q-ENERGY PRIVATE EQUITY SGEIC, S.A.
                        </Row>
                        <Row className='respuestaAlineada'>
                            - C/ Jenner 3, planta 4ª, 28010, Madrid
                        </Row>
                        <Row className='respuestaAlineada'>
                            - <a href="mailto:data.protection@qualitasequity.com">data.protection@qualitasequity.com</a>
                        </Row>
                        <Row className='respuestaGeneral'>
                            This Privacy Policy, which has been produced by the Controller, sets out all the situations in which the personal data of the users of our website
                            <a target="_blank" href="https://www.qualitas-energy.com">https:www.qualitas-energy.com</a>  (hereinafter, the Website) may be processed.
                        </Row>
                        <Row className='respuestaGeneral'>
                            As a User and data subject, you are informed that your personal data will be added to the Controller's Record of Processing Activities and classified under the "WEBSITE" section.
                        </Row>
                        <Row className='respuestaGeneral'>
                            Any personal data you are asked to provide when you use the Website are generally mandatory (unless otherwise indicated in the corresponding field) to comply with the stated purposes. Therefore, if you choose not to provide personal data, or the data you do provide are found to be inaccurate, we will not be able to respond to your requests, despite the fact that the Website's content remains visible.
                        </Row>
                        <Row className='respuestaGeneral'>
                            If you have any questions or queries about the processing of your personal data, you can write to the postal or electronic address indicated in this section. You can also visit the website of the Spanish Data Protection Authority (AEPD)
                            <a target="_blank" href="​https://www.agpd.es/portalwebAGPD/index-ides-idphp.php​">​https://www.agpd.es/portalwebAGPD/index-ides-idphp.php</a>
                        </Row>


                        <Row className="preguntasModal">
                            2. What is the purpose for processing your personal data?
                        </Row>
                        <Row className='respuestaGeneral'>
                            The personal data you provide when you use the Website will be processed by the Controller for the following purposes:
                        </Row>
                        <Row className='respuestaAlineada'>
                            -	Managing your record and registering you as a User to give you access to your Private Area where you can view and download all the information about your investments.
                        </Row>
                        <Row className='respuestaAlineada'>
                            -	Addressing the queries, requests and questions submitted by the User via the Website's contact form.
                        </Row>
                        <Row className='respuestaAlineada'>
                            -	Managing CVs submitted by, or received from, prospective applicants as part of the Entity's internal recruitment drives.
                        </Row>


                        <Row className="preguntasModal">
                            3.	What types of personal data do we process?
                        </Row>
                        <Row className='respuestaGeneral'>
                            We collect the following categories of personal data when the User uses the Website:
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Identifying data:</b> Data of legal representatives, address, name of user or any other similar identifier in the case of a legal person, surname(s), Spanish ID Document (DNI), registered address, telephone number, e-mail address.
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Contact information: </b> ​address, e-mail address and telephone number.
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Economic data: </b>invoicing address, bank details, quarterly reports, financial statements, income statements, balance sheets and any other personal data related to the products and services to which you subscribe.
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Transaction data: </b>details of investments made, distributions received by the User and any products and services to which you subscribe.
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Profile data: </b> name of User and password, history of investments made and any other profile information that we may add in the future.
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Technical data: </b> internet protocol (IP) address, logging-on details, type and version of browser, configuration of time zone and location, type and version of the browser's plug-ins, operating system and platform, along with any other technologies used on the devices by which you access this website.
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b> ● Usage data: </b> relevant information about how you use our website.
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Tracking data:  </b> information that we, or third parties, collect about you on account of our use of cookies and similar tracking technologies.
                        </Row>
                        <Row className='respuestaGeneral'>
                            We do not collect any personal data that belong to special categories of data.
                        </Row>

                        <Row className="preguntasModal">
                            4.	What is the legitimate purpose for processing your data?
                        </Row>
                        <Row className='respuestaGeneral'>
                            Your personal data are processed for the following legitimate purposes:
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Performance of investment agreement and effective provision of a service. ​ </b>
                            To manage the implementation and performance of the investment agreement and any services to which you subscribe; to manage your User profile and to address your queries, suggestions or requests; and to disclose your personal data to our service providers, as long as their disclosure is required for the provision of the service.
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Controller's fulfilment of legal obligations. </b>
                            To prevent fraud; to disclose your data to Public Authorities; and to respond to any third-party claims.
                        </Row>
                        <Row className='respuestaAlineada'>
                            <b>● Your express consent given by marking the corresponding box.</b>
                            To send the contact form and the CV.
                        </Row>


                        <Row className="preguntasModal">
                            5.	Who will your personal data be disclosed to?
                        </Row>
                        <Row className='respuestaGeneral'>
                            Your personal data may be disclosed to our external service providers, as long as their disclosure is required for the provision of the service, specifically:
                        </Row>
                        <Row className='respuestaAlineada'>
                            -	For hosting, storage and data processing services, to the entities that provide these services on our behalf.
                        </Row>
                        <Row className='respuestaAlineada'>
                            -	For managing any services related to the cloud storage of data.
                        </Row>
                        <Row className='respuestaGeneral'>
                            Moreover, your personal data may be disclosed to Public Authorities, so long as their disclosure is necessary for the Controller to fulfil a legal obligation or to address any third-party claims.
                        </Row>
                        <Row className='respuestaGeneral'>
                            As some of our service providers operate at a global level, the processing of your personal data may be transferred outside of the European Economic Area. In the event of an international transfer, we implement the necessary safeguards required under applicable data protection legislation and act in due accordance with the guidelines of the AEPD. For further information, visit the following link
                            <a target="_blank" href="https://www.aepd.es/es/derechos-y-deberes/cumple-tus-deberes/med
                        idas-de-cumplimiento/transferencias-internacionales"> https://www.aepd.es/es/derechos-y-deberes/cumple-tus-deberes/med
                                idas-de-cumplimiento/transferencias-internacionales</a>
                        </Row>

                        <Row className="preguntasModal">
                            6.	How long will your personal data be stored for?
                        </Row>
                        <Row className='respuestaGeneral'>
                            We will retain your data as a client for the time it takes to provide the service. Your personal data will subsequently be blocked and made available only to the corresponding authorities until such time as any liability arising from their processing operations elapses, at which point they will be securely erased or even anonymised so that we can continue to use them for analytical purposes.
                        </Row>
                        <Row className='respuestaGeneral'>
                            We will retain your data as a Website User until such time as you request to be removed from our list of users.
                        </Row>
                        <Row className='respuestaGeneral'>
                            We will keep your CV on file for the time it takes to complete any recruitment processes. With a view to keeping our records up-to-date, you may be asked to submit the latest version of your CV. If you are interested, you should note that the old version of your CV will be removed as soon as you submit the updated version.
                        </Row>

                        <Row className="preguntasModal">
                            7.	What are your rights?
                        </Row>
                        <Row className='respuestaGeneral'>
                            You may exercise the following rights at any time and free of charge:
                        </Row>
                        <Row className='respuestaAlineada'>
                            -	To receive confirmation about whether or not we are processing your personal data.
                        </Row>
                        <Row className='respuestaAlineada'>
                            -	To access your personal data.
                        </Row>
                        <Row className='respuestaAlineada'>
                            -	To rectify inaccurate or incomplete data.
                        </Row>
                        <Row className='respuestaAlineada'>
                            -	To have your data erased.
                        </Row>
                        <Row className='respuestaAlineada'>
                            -	To withdraw the processing consent you have previously given, e.g., for the purpose of sending marketing messages.
                        </Row>
                        <Row className='respuestaAlineada'>
                            -	To request the restriction of processing under any conditions stated in applicable regulations.
                        </Row>
                        <Row className='respuestaAlineada'>
                            -	To request the portability of your data.
                        </Row>
                        <Row className='respuestaAlineada'>
                            -	To object to the processing of your data in any case where they are used by us to send you marketing messages based on our legitimate interest.
                        </Row>
                        <Row className='respuestaAlineada'>
                            -	To submit a data protection claim to the Spanish Data Protection Authority
                            <a target="_blank" href="​www.aepd.es​">(www.aepd.es​)</a>
                        </Row>
                        <Row className='respuestaGeneral'>
                            To this end, contact us by sending a letter or e-mail (with "Data Protection" in the subject line) to the postal or electronic address indicated in section 1 of this Privacy Policy, along with a copy of your ID document.
                        </Row>
                        <Row className='respuestaGeneral'>
                            As a User, you should bear in mind that computer information systems are never absolutely secure. Whenever personal data are provided via the internet, the information may be collected and processed by unauthorised third parties acting without your consent.
                        </Row>

                        <Row className="preguntasModal">
                            8.	User's Responsibility
                        </Row>
                        <Row className='respuestaGeneral'>
                            As a User, whenever you provide personal data via our forms, you are proclaiming to be of legal age and asserting that the corresponding personal data are accurate, truthful, complete and up-to-date. To this end, as a User, you are responsible for ensuring that all submitted personal data are accurate and that they are kept up-to-date so that they accurately reflect your current situation.
                        </Row>
                        <Row className='respuestaGeneral'>
                            Moreover, to the extent that you submit the personal data of third parties, you assert that you have informed them of the situations in which their data may be processed and of the content of this Privacy Policy. You also assert that the third parties have expressly authorised you to submit their personal data for the stated purposes.
                        </Row>

                        <Row className="preguntasModal">
                            9.	Links to third-party websites.
                        </Row>
                        <Row className='respuestaGeneral'>
                            Our website may include links to websites, plug-ins and applications of third parties. If you click on these links or enable any such connection, you may be authorising third parties to collect or share your personal data. The ways in which the owners of those websites process personal data are not covered by the scope of this privacy policy. On this basis, under no circumstances do we assume responsibility for the information collected by third parties, or for the protection and privacy of the information you submit via these websites. You are urged to carefully review the privacy policies of all third-party websites in order to determine how they collect and share your information.
                        </Row>

                        <Row className="preguntasModal">
                            10.	Security measures
                        </Row>
                        <Row className='respuestaGeneral'>
                            We treat your personal data in confidence and secrecy by implementing, at all times, the technical and organisational measures required to guarantee the security of your data and prevent any possible alteration, loss, destruction and unauthorised processing or access, in view of the state of the art, the nature of the stored data, their processing and any risks to which they are exposed.

                        </Row>
                        <Row className='respuestaGeneral'>
                            Moreover, we require all our service providers who act as data processors to enter into an agreement with us with a view to ensuring, where possible, that they comply with applicable data protection regulations.
                        </Row>

                        <Row className="preguntasModal">
                            11.	Updating the Privacy Policy
                        </Row>
                        <Row className='respuestaGeneral'>
                            We may be required to update the Privacy Policy to reflect any regulatory developments, the recommendations of competent authorities or changes to the way in which your personal data are processed. Whenever the privacy policy is updated, you will be able to access the latest version here.
                        </Row>
                    </Row>

                </Modal>
            }
        </div>
    );
};

export default AvisoLegal;
