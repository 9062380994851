import React, { useState, useEffect } from 'react';
import {
    Form, Icon, Input, Button, Checkbox, Row, Modal,
    Col,
    message,
} from 'antd';
import logo from '../../Assets/images/logo.png';
import './Login.css'
import { loginService } from '../../Servicios/login.service';
const _ACTUALIZARPASS = new loginService();



const RecuperarPassword = (props: any) => {

    const [inicializado, setInicializado] = useState(false);
    const [pass, setPass] = useState("");
    const [confpass, setConfPass] = useState("");
    const [tokenValido, setTokenValido] = useState(false);

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get('token');
    let email = params.get('email');

    useEffect(() => {
        if (inicializado == false) {
            setInicializado(true);
            validarToken();
        }
    });

    const actualuzarPass = () => {
        if (pass == confpass) {
            let data = {
                loginName: email,
                password: pass
            }
            _ACTUALIZARPASS.actualizarContrasenia(data).then((res: any) => {
                if (res.result) {
                    success();
                }
            })
        }
        else {
            message.error("la constraseña no coincide");
        }
    }

    const validarToken = () => {
        _ACTUALIZARPASS.validarToken(email, token).then((res: any) => {

            setTokenValido(res.result);
        })
    }

    const success = () => {
        Modal.success({
            title: 'La contraseña fue actualizada...',
            onOk() { window.location.href = "/"; }
        });
    }

    return (
        <div className='fondoLogin' >

            <Row  >
                <Row>
               
                </Row>
                <Col span={8}>
                </Col>
                {tokenValido == true ?
                    <Col span={8} className='contendorLogin'>
                         <Row>
                        <Col span={8}>
                            {<img src={logo} className='logoInversores' />}
                        </Col>
                        <Col span={8}>
                        </Col>
                        <Col span={8}>
                            <h3 style={{ textAlign: "right" }}>Portal Inversores</h3>
                        </Col>
                        <br></br>
                        <br></br> 
                        <br></br>
                    </Row>
                    <br/>
                    <h1 style={{ textAlign: "left", paddingLeft: "60px" }}>Recuperar contraseña</h1>

                        <Form className="loginForm">
                            <Form.Item> Ingresar Contraseña
                            <Input
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="Contraseña"
                                    type="password"
                                    value={pass}
                                    onChange={(e) => setPass(e.target.value)}

                                />
                            </Form.Item>

                            <Form.Item>
                                Confirmar contraseña
                            <Input
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="password"
                                    placeholder="Contraseña"
                                    value={confpass}
                                    onChange={(e) => setConfPass(e.target.value)}

                                />
                            </Form.Item>

                            <Button type="primary" htmlType="submit" className="botonLogin" onClick={actualuzarPass}>
                                Restablecer
                               </Button>
                            <br />
                            {/* <a href="">Regístrate ahora</a> */}
                        </Form>
                    </Col>
                    : <Row > <h3 style={{ color: "red" }}>El token no es válido</h3></Row>}
                <Col span={8}>
                </Col>

            </Row>
            <div className="contenedorInferior">d</div>
        </div>
    );
}

export default RecuperarPassword;