const idiomas = {
    es:{
      idioma: "Idioma",
      inversores: "Inversores",
      documentos: "Documentos",
      analiza: "Analiza tu inversión",
      cargando: "Cargando Datos...",
      buscar: "Buscar inversor",
      busquedaHolder: "Escriba el nombre del inversor",
      descargarExcel: "Descargar Excel",
      grupo: "Grupo",
      compromiso: "Compromiso",
      compromisoGrafico: "Comp.",
      contribuciones: "Contribuciones",
      distribuciones: "Distribuciones",
      participaciones: "Participaciones",
      acciones: "Acciones",
      tuInversion: "Tu inversión",
      documentosInversores: "Documentos de inversores",
      nombreDocumento: "Nombre del Documento",
      fondos: "Fondos",
      anuncios: "Anuncios",
      portalInversor: "Portal del inversor",
      contacto: "Contacto",
      accesoDenegado: "Acceso denegado",
      accesoDenegadoTexto: "El usuario actual no tiene acceso a esta aplicación",
      SeleccionaDocumento: "Selecciona un documento",
      ampliar: "Ampliar",
      seleccionarTodos:"Seleccionar Todos",
      RecuperarContrasenia:"Recuperar contraseña",
      versionExcel:"Cuál es su versión de excel?",
      textSiete:"2007 o anterior",
      textDiez:"2010 o posterior",
      seleccionVersion:"Seleccione su versión",
      title: "Portal Inversores",
      noDocuments: "Por favor, seleccione un vehículo para mostrar sus documentos"
    },
    en: {
      idioma: "Language",
      inversores: "Investors",
      documentos: "Documents",
      analiza: "Analyze your investment",
      cargando: "Loading Data...",
      buscar: "Search investor",
      busquedaHolder: "investor name",
      descargarExcel: "Download Excel",
      grupo: "Group",
      compromiso: "Commitment",
      compromisoGrafico: "Comm.",
      contribuciones: "Disbursed",
      distribuciones: "Distributions",
      participaciones: "Units",
      acciones:"Shares",
      tuInversion: "Your investment",
      documentosInversores: "Investor documents",
      nombreDocumento: "Document name",
      fondos: "Funds",
      anuncios: "Announcements",
      portalInversor: "Investor portal",
      contacto: "Contact",
      accesoDenegado: "Access denied",
      accesoDenegadoTexto: "The current user does not have access to this application",
      SeleccionaDocumento: "Select a Document",
      ampliar: "Open",
      seleccionarTodos: "Select all",
      RecuperarContrasenia:"Forgot Password",
      versionExcel:"what is your version of excel?",
      textSiete:"2007 or earlier",
      textDiez:"2010 or later",
      seleccionVersion:"Select your version",
      title: "Investors Portal",
      noDocuments: "Please, select a vehicle to display the documents"
    }
  }
  export {idiomas}; 