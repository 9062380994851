import axios from 'axios';
import { GetIdList } from '../Modelos/posicion.model';
import {Utils} from '../utils/Utils';



const ReportsAPI = {
    GenerateCapitalAccount(data:GetIdList):Promise<any> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].webAPI}PDF/GenerateCapitalAccount`,data, {
                responseType: 'arraybuffer',
                headers: {
                  'Accept': 'application/pdf'
                }
            })
                .then((data:any) => {
                    if (data !== null) {
                        return resolve(data.data);
                    }
                    else {
                        return reject(null);
                    }
                })
            });
        });
    },

    GenerateCapitalCall(data:GetIdList):Promise<any> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].webAPI}PDF/GenerateCapitalCall`,data, {
                responseType: 'arraybuffer',
                headers: {
                  'Accept': 'application/pdf'
                }
            })
                .then((data:any) => {
                    if (data !== null) {
                        return resolve(data.data);
                    }
                    else {
                        return reject(null);
                    }
                })
            });
        });
    },

    
    GenerateDistributionNotice(data:GetIdList):Promise<any> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].webAPI}PDF/GenerateDistributionNotice`,data, {
                responseType: 'arraybuffer',
                headers: {
                  'Accept': 'application/pdf'
                }
            })
                .then((data:any) => {
                    if (data !== null) {
                        return resolve(data.data);
                    }
                    else {
                        return reject(null);
                    }
                })
            });
        });
    },

    GenerateReportFiscal(data:GetIdList):Promise<any> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].webAPI}PDF/GenerateReportFiscal`,data, {
                responseType: 'arraybuffer',
                headers: {
                  'Accept': 'application/pdf'
                }
            })
                .then((data:any) => {
                    if (data !== null) {
                        return resolve(data.data);
                    }
                    else {
                        return reject(null);
                    }
                })
            });
        });
    },


}

export default ReportsAPI;