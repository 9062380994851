import React, {useEffect} from 'react';
import { IModalVideoProps } from './IModalVideoProps';
import styles from './ModalVideo.module.scss';
import { Modal } from 'antd';

const ModalVideo: React.SFC<IModalVideoProps> = (props) => {
  return (
    <div className={`${styles.modalVideo}`}>
      <Modal
        width={640}
        visible={props.modalVideoVisible}
        onOk={() => props.setModalVideoVisible(false)}
        onCancel={() => props.setModalVideoVisible(false)}
        footer={[
          null,
          null,
        ]}
      >
       <iframe 
        style={{marginTop:30}} 
        src={props.url} 
        width="100%" 
        height="361" 
        allow="autoplay; fullscreen">
       </iframe>
      </Modal>
    </div>
  );
};

export default ModalVideo;
