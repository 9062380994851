export class String {
 
    public static empty: string = "";
   
    public static isNullOrWhiteSpace(value: string): boolean {
      try {
        if (value == null || value == undefined)
          return false;
   
        return value.replace(/\s/g, '').length < 1;
      }
      catch (e) {
        return false;
      }
    }
   
    public static format(value: any, ...args: any[]): string {
      try {
        return value.replace(/{(\d+(:.*)?)}/g, (match: any, i: any) => {
          var s = match.split(':');
          if (s.length > 1) {
            i = i[0];
            match = s[1].replace('}', '');
          }
   
          var arg = String.formatPattern(match, args[i]);
          return typeof arg != 'undefined' && arg != null ? arg : String.empty;
        });
      }
      catch (e) {
        return String.empty;
      }
    }

    public static currencyFormatByLanguage(value:any, lan?:string){
      const lang = lan === undefined ? localStorage["idioma"] : lan;
      switch(lang){
        case "en": return `€ ${value.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');          
        default: return `€ ${value.toFixed(2)}`.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
    }
   
    private static formatPattern(match: any, arg: any): string {
      switch (match) {
        case 'L':
          arg = arg.toLowerCase();
          break;
        case 'U':
          arg = arg.toUpperCase();
          break;
        default:
          break;
      }
   
      return arg;
    }

  }