import axios from 'axios';
import {Utils} from '../utils/Utils';

const DocumentsDataRoomAPI = {
    get(relativeUrl:string,name:string,user:string,idioma:string) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].url_inversores}/DataRoom?relativeUrl=${relativeUrl}&name=${name}&user=${user}&lang=${idioma}`)
                    .then((data) => 
                    { 
                        if (data !== null) {
                            return resolve(data);
                        }
                        else {
                            return reject(null);
                        }
                    })
            });
        });
    },
    getDataRoomVideos(list:string) {
        let token = Utils.obtenerCookie("token");
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].url_inversores}/investors/sharepoint/getlistvideos?list=${list}`,
                {headers: {Authorization: `Bearer ${token}`}
            })
                    .then((data) => 
                    { 
                        if (data !== null) {
                            return resolve(data.data.result);
                        }
                        else {
                            return reject(null);
                        }
                    })
            });
        });
    },
    getAllSharepointDocuments(libraryPath:string) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].url_inversores}/DataRoom?libraryPath=${libraryPath}`)
                    .then((data) => 
                    { 
                        if (data !== null) {
                            return resolve(data);
                        }
                        else {
                            return reject(null);
                        }
                    })
            });
        });
    },
    getVideo(vieoName:string,containerName:string) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].url_inversores}/DataRoom?vieoName=${vieoName}&containerName=${containerName}`)
                    .then((data) => 
                    { 
                        if (data !== null) {
                            return resolve(data);
                        }
                        else {
                            return reject(null);
                        }
                    })
            });
        });
    },
    upload(formData: any, idElemento:any, carpeta:any, library:string, urlWeb:string): Promise<any> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.serviceUrl}/crmfiles/files`, formData, {
                    headers: {
                        "FolderName": carpeta,
                        "LibraryName": library,
                        "UrlWeb": urlWeb,
                        "IdElemento": idElemento
                    }
                }).then((data) => {
                    if (data !== null) {
                        return resolve(data.data);
                    }
                    else {
                        return reject(null);
                    }
                })
            });
        });
    },
    delete(relativeUrl: string): Promise<any> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.serviceUrl}/crmfiles/delete?relativeUrl=${relativeUrl}`)
                .then((data) => {
                    if (data !== null) {
                        return resolve(data.data);
                    }
                    else {
                        return reject(null);
                    }
                })
            });
        });
    },
    deleteBlobStorage(relativeUrl: string): Promise<any> {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.serviceUrl}api/DataRoom/${relativeUrl}`)
                .then((data) => {
                    if (data !== null) {
                        return resolve(data.data);
                    }
                    else {
                        return reject(null);
                    }
                })
            });
        });
    }
}

export default DocumentsDataRoomAPI;