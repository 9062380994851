import React, { useState, useEffect } from 'react';
import './AdminUsuarios.css';
import { Layout, Menu, Icon, Card, Tabs, Table, Input, Button, Row, Col, Select, DatePicker, Carousel, Checkbox, message } from 'antd';
import 'antd/dist/antd.css';
import PrincipalHeader from '../Header/Header';
import { inversoresService } from '../../Servicios/inversores.service';
import { institutionServices } from '../../Servicios/instituciones.service';
const _INVERSORESSERVICE = new inversoresService();
const _INSTITUCIONESSERVICE = new institutionServices();
const { Option } = Select;
const { Search } = Input;

const AdminUsuarios = () => {

    const [loading, setLoading] = useState(false);
    const [listados, setListados]: any = useState([]);
    const [listadosBackUp, setListadosBackUp] = useState([]);
    const [listadoColocadores, setListadoColocadores] = useState([]);
    const [listadoPeips, setListadoPeips] = useState([]);
    const [investorSelectedRowKeys, setinvestorSelectedRowKeys] = useState([]);
    const [contactSelectedRowKeys, setcontactSelectedRowKeys] = useState();
    const [colocadorSeleccionado, setColocadorSeleccionado] = useState("");
    const [peipSeleccionado, setPeipSeleccionado] = useState("");
    const [grupoSeleccionado, setgrupoSeleccionado] = useState("");
    const [textoFiltro, setTextoFiltro] = useState("");
    const [ListadoContactos, setListadoContactos] = useState([]);
    const [ListadoContactosBackup, setListadoContactosBackUp] = useState([]);
    const [ColocadorContactSeleccionado, setColocadorContactSeleccionado] = useState("");
    const [textoFiltroContacto, settextoFiltroContacto] = useState("");
    const [institutionsList, setInstitutionsList]: any = useState([]);
    const [ContactInstitutionslist, setContactInstitutionslist]: any = useState([]);
    const [InstitucionSeleccionada, setInstitucionSeleccionada] = useState("");
    const [ListaGrupos, setListaGrupos] = useState([]);

    useEffect(() => {
        obtenerInstituciones();
        obtenerListados();
    }, []);

    const obtenerContactos = (contactInst: any, institutions: any) => {
        _INVERSORESSERVICE.getContactList().then((res: any) => {
            let listadoContactos: any = [];
            res.map((x: any, i: number) => {
                let idInstitution = '';
                let nombreInstitution = '';
                if (contactInst.filter((filtro: any) => filtro.IdContact === x.IdContact).length > 0) {
                    idInstitution = contactInst.filter((filtro: any) => filtro.IdContact === x.IdContact)[0].IdInstitution;
                    nombreInstitution = institutions.filter((filtro: any) => filtro.IdInstitution === idInstitution)[0].InstitutionLong;
                }
                listadoContactos.push({
                    IdContact: x.IdContact,
                    nombre_contacto: x.Name,
                    IdInstitution: idInstitution,
                    nombre_institucion: nombreInstitution,
                    key:x.IdContact
                })
                if (i + 1 === res.length) {
                    setListadoContactosBackUp(listadoContactos);
                    setListadoContactos(listadoContactos);
                }
            });
        });
    }

    const obtenerInstituciones = () => {
        _INSTITUCIONESSERVICE.getInstitutionsList().then((x: any) => {
            setInstitutionsList(x);
            setListadoColocadores(x.filter((filtro: any) => filtro.IdInstitutionType === 11));
            obtenerContactosInstituciones(x);
        });
    }

    const obtenerContactosInstituciones = (institutions: any) => {
        _INSTITUCIONESSERVICE.getContactInstitutionsList().then((x: any) => {
            setContactInstitutionslist(x);
            obtenerContactos(x, institutions);
        });
    }

    const obtenerListados = () => {
        _INVERSORESSERVICE.obtenerListaRelaciones().then((res: any) => {
            let colocadores: any = [];
            let peips: any = [];
            let ListaGrupos: any = [];

            setListados(res);
            setListadosBackUp(res);
            if(res != null){
                res.map((x: any, i: number) => {
                    colocadores.push(x.Colocador);
                    peips.push(x.PeipNameLong);
                    var el: any = {
                        IdGrupo: x.IdGrupo,
                        Grupo: x.Grupo
                    }
                    ListaGrupos.push(el);
                    if (i + 1 === res.length) {
                        //Remover duplicados
                        let col = colocadores.filter((v: any, i: any) => colocadores.indexOf(v) === i);
                        let peip = peips.filter((v: any, i: any) => peips.indexOf(v) === i);
                        let grupo = ListaGrupos.filter((v: any, i: any, a: any) => a.findIndex((t: any) => (t.Grupo === v.Grupo)) === i)
                        //let contact = res.filter((v:any,i:any,a:any)=>a.findIndex((t:any)=>(t.nombre_contacto === v.nombre_contacto))===i)
                        //setListadoColocadores(col);
                        setListadoPeips(peip);
                        setListaGrupos(grupo);
                        //setListadoContactosBackUp(contact);
                        //setListadoContactos(contact);
                    }
                });
            }
        })
    }

    const filterInvestorsByPeip = (e: any) => {
        let arrayInvestorstemp: any = []
        setPeipSeleccionado(e);
        arrayInvestorstemp = listadosBackUp.filter((x: any) => x.PeipNameLong === e);

        if (colocadorSeleccionado != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x: any) => x.Colocador === colocadorSeleccionado);
        }

        if (grupoSeleccionado != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x:any)=> x.IdGrupo === grupoSeleccionado);
        }

        if (textoFiltro != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x: any) => x.Investor.toLowerCase().includes(textoFiltro));
        }
        setListados(arrayInvestorstemp);

    }

    const filterInvestorsByColocador = (e: any) => {
        let arrayInvestorstemp: any = [];
        setColocadorSeleccionado(e);
        arrayInvestorstemp = listadosBackUp.filter((x: any) => x.Colocador === e);

        if (peipSeleccionado != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x: any) => x.PeipNameLong === peipSeleccionado);
        }

        if (grupoSeleccionado != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x:any)=> x.IdGrupo === grupoSeleccionado);
        }

        if (textoFiltro != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x: any) => x.Investor.toLowerCase().includes(textoFiltro));
        }
        setListados(arrayInvestorstemp);
    }

    const filterTextInvestor = (e: any) => {
        let arrayInvestorstemp: any = [];
        setTextoFiltro(e);
        arrayInvestorstemp = listadosBackUp.filter((x: any) => x.Investor.toLowerCase().includes(e));
        if (peipSeleccionado != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x: any) => x.PeipNameLong === peipSeleccionado);
        }

        if (grupoSeleccionado != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x:any)=> x.IdGrupo === grupoSeleccionado);
        }

        if (colocadorSeleccionado != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x: any) => x.Colocador === colocadorSeleccionado);
        }
        setListados(arrayInvestorstemp);
    }

    const filterContactByInstitution = (e: any) => {
        let arrayInvestorstemp: any = [];
        setInstitucionSeleccionada(e);
        arrayInvestorstemp = ListadoContactosBackup.filter((x: any) => x.nombre_institucion.toLowerCase().includes(e));

        if (grupoSeleccionado != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x:any)=> x.IdGrupo === grupoSeleccionado);
        }

        if (textoFiltroContacto != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x: any) => x.nombre_contacto.toLowerCase().includes(textoFiltroContacto));
        }

        if (ColocadorContactSeleccionado != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x: any) => x.IdInstitution === ColocadorContactSeleccionado);
        }
        setListadoContactos(arrayInvestorstemp);
    }

    const filterContactosByGrupo = (e: any) => {
        let arrayInvestorstemp: any = [];
        setgrupoSeleccionado(e);
        arrayInvestorstemp = ListadoContactosBackup.filter((x: any) => x.IdInstitution === e);

        if (ColocadorContactSeleccionado != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x: any) => x.IdInstitution === ColocadorContactSeleccionado);
        }

        if (textoFiltroContacto != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x: any) => x.nombre_contacto.toLowerCase().includes(textoFiltroContacto));
        }
        if (InstitucionSeleccionada != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x: any) => x.nombre_institucion.toLowerCase().includes(InstitucionSeleccionada));
        }
        setListadoContactos(arrayInvestorstemp);
    }

    const filterContactsByColocador = (e: any) => {
        let arrayInvestorstemp: any = [];
        setColocadorContactSeleccionado(e);
        arrayInvestorstemp = ListadoContactosBackup.filter((x: any) => x.IdInstitution === e);

        if (grupoSeleccionado != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x:any)=> x.IdGrupo === grupoSeleccionado
            );
        }

        if (textoFiltroContacto != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x: any) => x.nombre_contacto.toLowerCase().includes(textoFiltroContacto));
        }
        if (InstitucionSeleccionada != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x: any) => x.nombre_institucion.toLowerCase().includes(InstitucionSeleccionada));
        }
        setListadoContactos(arrayInvestorstemp);
    }

    const filterTextContact = (e: any) => {
        let arrayInvestorstemp: any = [];
        settextoFiltroContacto(e);
        arrayInvestorstemp = ListadoContactosBackup.filter((x: any) => x.nombre_contacto.toLowerCase().includes(e));

        if (grupoSeleccionado != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x:any)=> x.IdGrupo === grupoSeleccionado);
        }

        if (ColocadorContactSeleccionado != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x: any) => x.IdInstitution === ColocadorContactSeleccionado);
        }

        if (InstitucionSeleccionada != "") {
            arrayInvestorstemp = arrayInvestorstemp.filter((x: any) => x.nombre_institucion.toLowerCase().includes(InstitucionSeleccionada));
        }
        setListadoContactos(arrayInvestorstemp);
    }

    const onSelectChangeInvestors = (selectedRowKeys: any, selectedRows: any) => {
        setinvestorSelectedRowKeys(selectedRows)
    };

    const onSelectChangeContacts = (selectedRowKeys: any, selectedRows: any) => {
        console.log(selectedRows)
        setcontactSelectedRowKeys(selectedRows)
    };

    const rowSelectionInvestors = {
        investorSelectedRowKeys,
        onChange: onSelectChangeInvestors,
        hideDefaultSelections: true,
    };

    const rowSelectionContacts = {
        contactSelectedRowKeys,
        onChange: onSelectChangeContacts,
        hideDefaultSelections: true,
    };

    const InvestorColumns = [
        { title: 'Inversor', dataIndex: 'Investor', key: 'IdInvestor' },
        { title: 'Peip', dataIndex: 'PeipNameLong', key: 'IdPeip' },

    ];

    const ContactColumns = [
        { title: 'Contacto', dataIndex: 'nombre_contacto', key: 'IdContact' },
        { title: 'Institución', dataIndex: 'nombre_institucion', key: 'IdContact' }
    ];

    const guardar = () => {
        setLoading(true);
        contactSelectedRowKeys.map((contacto: any, countContact: number) => {
            investorSelectedRowKeys.map((inversor: any, countInvestor: number) => {
                _INVERSORESSERVICE.addContactInvestor(inversor.IdInvestor, contacto.IdContact).then((x: any) => {
                    console.log(`[contacto:${contacto.IdContact},inversor:${inversor.IdInvestor}] => ${x}`);
                    if (countContact + 1 === contactSelectedRowKeys.length && countInvestor + 1 === investorSelectedRowKeys.length) {
                        setLoading(false);
                        message.success('Los contactos fueron guardados');
                    }
                });
            });
        });
    }

    return (
        <div>
            {/* <PrincipalHeader></PrincipalHeader> */}
            <Row>
                <Col span={1}></Col>
                <Col span={21} style={{ textAlign: 'right', padding: 10 }}>
                    <Button loading={loading} type="primary" onClick={guardar}>Guardar</Button>
                </Col>
            </Row>
            <Row>
                <Col span={1}></Col>
                <Col span={10}>
                    <Card title="Inversores" style={{ minHeight: 315 }}>
                        <Row className="Filas">
                            <Col span={6}></Col>
                            <Col span={12}>
                                <Select defaultValue="PEIP" style={{ width: '100%' }} onChange={filterInvestorsByPeip}>
                                    {
                                        listadoPeips.map((x: any) => <Option value={x}>{x}</Option>)
                                    }
                                </Select>
                            </Col>
                            <Col span={6}></Col>
                        </Row>

                        <Row className="Filas">
                            <Col span={6}></Col>
                            <Col span={5}>
                                <Select defaultValue="Grupo" style={{ width: '100%' }}>
                                    {
                                        ListaGrupos.map((x: any) => <Option value={x.IdGrupo}>{x.Grupo}</Option>)
                                    }
                                </Select>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={5}>
                                <Select defaultValue="colocador" style={{ width: '100%' }} onChange={filterInvestorsByColocador}>
                                    {
                                        listadoColocadores.map((x: any) => <Option value={x.IdInstitution}>{x.InstitutionLong}</Option>)
                                    }
                                </Select>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                        <Row className="Filas">
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}></Col>
                            <Col xs={0} sm={0} md={12} lg={12} xl={12}> 
                                <Search
                                    placeholder="Buscar inversor"
                                    onSearch={value => filterTextInvestor(value)}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}></Col>
                        </Row>

                        <Row className="Filas">
                            <Col span={24}>

                                <br />
                                <Table
                                    columns={InvestorColumns}
                                    dataSource={listados}
                                    rowSelection={rowSelectionInvestors}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={1}></Col>
                <Col span={10}>
                    <Card title="Contactos" style={{ minHeight: 315 }}>
                        <Row className="Filas">
                            <Col span={6}></Col>
                            <Col span={5}>
                                <Select defaultValue="Grupo" style={{ width: '100%' }} onChange={filterContactosByGrupo}>
                                    {
                                        ListaGrupos.map((x: any) => <Option value={x.IdGrupo}>{x.Grupo}</Option>)
                                    }
                                </Select>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={5}>
                                <Select defaultValue="colocador" style={{ width: '100%' }} onChange={filterContactsByColocador}>
                                    {
                                        listadoColocadores.map((x: any) => <Option value={x.IdInstitution}>{x.InstitutionLong}</Option>)
                                    }
                                </Select>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                        <Row className="Filas">
                            <Col span={6}></Col>
                            <Col span={12}>
                                <Search
                                    placeholder="Buscar Institución"
                                    onSearch={value => filterContactByInstitution(value)}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                        <Row className="Filas">
                            <Col span={6}></Col>
                            <Col span={12}>
                                <Search
                                    placeholder="Buscar Contacto"
                                    onSearch={value => filterTextContact(value)}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                        <Row className="Filas">
                            <Col span={24}>

                                <br />
                                <Table
                                    columns={ContactColumns}
                                    dataSource={ListadoContactos}
                                    rowSelection={rowSelectionContacts}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={1}></Col>
            </Row>
        </div>
    );
};

export default AdminUsuarios;
