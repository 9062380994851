import axios from 'axios';
import {Utils} from '../utils/Utils';

export class powerBIService {

    public getBIToken():Promise<any[]>{
        return new Promise((resolve,reject)=>{            
            try {
                Utils.getConfig().then((config: any) => {     
                    axios.get(`${config.data[0].url_inversores}PowerBIToken`) 
                    .then((data) => { 
                        
                        if (data !== null) {
                            return resolve(data.data);
                        }
                        else {
                            return reject(null);
                        }
                    })
                });
            } catch (error) {
                return reject(null);
            }
        });
    }
}