import axios from 'axios';
import {Utils} from '../utils/Utils';

const InvestorsGridAPI = {
    get(IdContact:number) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {                
                axios.get(`${config.data[0].url_inversores}InvestorsGrid/investments?IdContact=${IdContact}`)
                    .then((data) => 
                    { 
                        if (data !== null) {
                            return resolve(data.data);
                        }
                        else {
                            return reject(null);
                        }
                    })
            });
        });
    }
}

export default InvestorsGridAPI;
