import React, { useEffect, useState } from 'react';
import { IHeadBlueBarProps } from './IHeadBlueBarProps';
import styles from './HeadBlueBar.module.scss';
import { Row, Col, Icon, Tooltip, Modal } from 'antd';
import moment from 'moment';
import { Utils } from '../../utils/Utils';


const HeadBlueBar: React.SFC<IHeadBlueBarProps> = (props) => {

  const idioma =
    <>
      {props.idioma === 'en' ?
        <span onClick={() => handleLanguageChange('es')} style={{ cursor: 'pointer' }}>
          <span>Español</span>
        </span>
        :
        <span onClick={() => handleLanguageChange('en')} style={{ cursor: 'pointer' }}>
          <span>English</span>
        </span>
      }
    </>;

  function accesoDenegdo() {
    console.log(props.traducciones.accesoDenegado)
    Modal.warning({
      title: `${props.traducciones.accesoDenegado}`,
      content: `${props.traducciones.accesoDenegadoTexto}`,
      onOk() {
        window.location.href = "/login";
      },
    });
  }

  useEffect(() => {

    let token = localStorage.token;
    if (token === "" || token === undefined) {
      window.location.href = "/login";
    }
    let userData = Utils.parseJwt(token);
    if (props.traducciones.length !== 0) {
      let systems = userData.systems.split('-');
      //Validar acceso al portal del inversor
      if (window.location.pathname === "/" && systems.indexOf('1') === -1) {
        accesoDenegdo()
      }
      //validar acceso al DataRoom
    /*   if (window.location.pathname === "/DataRoom" && systems.indexOf('2') === -1) {
        accesoDenegdo()
      } */
    }
    props.setUserData(userData);
    const now = Date.now().valueOf() / 1000
    if (userData.exp < now) {
      console.log('expirado')
      window.location.href = "/login";
    }
    /* if (userData.nbf > now) {
      console.log('nbf token')
      window.location.href = window.location.pathname === 'DataRoom' ? "/login/DataRoom" : "/login/PortalInversor";
    } */
  }, [props.traducciones]);

  const handleLanguageChange = (e: any) => {
    localStorage.idioma = e;
    props.setIdioma(e);
  }

  const logOut = () => {
    localStorage.clear();
    Utils.delete_cookie('token');
    window.location.href = "/login";
  }

  return (
    <div className={`${styles.headBlueBar}`}>
      <Row>
        <Col xs={0} sm={0} md={2} lg={2} xl={2} className={styles.contenedorFila}>&nbsp;</Col>
        <Col xs={24} sm={24} md={2} lg={2} xl={2} className={styles.contenedorFila}>
          {window.location.pathname === "/DataRoom" && props.idioma === "en" ?
            <div>&nbsp;</div>
            : 
            <Tooltip placement="bottom" title={idioma}>
              {props.idioma === 'en' ?
                <span>English</span>
                :
                <span>Español</span>
              }
            </Tooltip>
          }

        </Col>
        <Col xs={0} sm={0} md={8} lg={8} xl={8} className={styles.contenedorFila}>&nbsp;</Col>
       
                <Col xs={0} sm={0} md={2} lg={2} xl={2}
          className={styles.contenedorFila}
          >&nbsp;
          </Col>
        <Col xs={0} sm={0} md={2} lg={2} xl={2}
        className={styles.contenedorFila}
          >&nbsp;
        </Col> 
        <Col xs={24} sm={24} md={3} lg={3} xl={3}
          className={`${styles.topBarOption} ${styles.contenedorFila}`}
          onClick={() => window.location.href = '/'}>
          {props.traducciones.portalInversor}
        </Col>
  
        <Col xs={0} sm={0} md={5} lg={5} xl={5} className={styles.contenedorFila}>
          {props.userData !== undefined &&
            <>
              <Icon type="user" />&nbsp; {props.userData.userName} &nbsp;
            </>
          }
          <Icon type="login" className="icono-logout" onClick={logOut} />
        </Col>
      </Row>
    </div>
  );
};

export default HeadBlueBar;
