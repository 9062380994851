import React, { useState, useEffect, Children } from 'react';
import { IInvestorsTableProps } from './IInvestorsTableProps';
import './TablaInversores.css';
import styles from './InvestorsTable.module.scss';
import { String } from '../../utils/Strings';
import { IuserData } from '../../Modelos/userData.models';
import InvestorsGridAPI from '../../Api/api.Investors';
import { IdataGridInvestors } from '../../Modelos/dataGridInvestors.model';
import { Tree, Row, Col, Spin, Button } from 'antd';
import Search from 'antd/lib/input/Search';
import { debug } from 'console';
const { TreeNode } = Tree;

const InvestorsTable: React.SFC<IInvestorsTableProps> = (props) => {

  const [Loading, setLoading] = useState(true);
  const [dataInvestors, setDataInvestors]: any = useState<Array<IdataGridInvestors>>([]);
  const [grupos, setGrupos] = useState([]);
  const [dataSourceTree, setDataSourceTree]: any = useState([]);
  const [totalGrid, setTotalGrid]: any = useState([]);
  const [valorfiltro, setValorfiltro] = React.useState("");

  //Tree
  const [expandedKeys, setExpandedKeys] = useState<string[]>(['Grupo-0']);
  const [checkedKeys, setCheckedKeys] = useState<string[]>();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);

  useEffect(()=>{
    mapDataGrid(props.dataGridInitial)
  },[props.dataGridInitial])

  useEffect(() => {
    if (props.InfoInversores.length > 0) {
      setDataInvestors(props.InfoInversores);
      setGrupos(props.InfoGrupos);
      armarGrid((props.fondoSeleccionado), "", props.InfoGrupos, props.InfoInversores);
      setCheckedKeys([]);
      setExpandedKeys(['Grupo-0'])
    }
  }, [props.fondoSeleccionado, props.traducciones]);

  const getDataGrid = () => {
    setLoading(true);
    props.setInfoInversores([]);
    InvestorsGridAPI.get(props.userData.IdContact).then((json: any) => {
      mapDataGrid(json);
    }).catch(err => console.error(err))
  }

  const mapDataGrid = (json:any)=>{
    props.setListadoFechas(json); 
      let arraySumaPorInversor: any = [];
      let arrayGrupos: any = [];
      json.map((x: IdataGridInvestors, i: number) => {
        let existe = arraySumaPorInversor
          .map((e: any) => { return `${e.Fondo}-${e.institution}-${e.SharesType}`; })
          .indexOf(`${x.PeipVehicle}-${x.InstitutionLong}-${x.SharesType}`);
        if (existe == -1) {
          let el = {
            institution: x.InstitutionLong,
            Fondo: x.PeipVehicle,
            idInvestor: x.IdInvestor,
            Commitment: x.Commitment,
            Amount: x.Amount,
            Capital_Gain: x.CapGain_Buyback2,
            Shares: x.Shares,
            Colocador: x.Colocador,
            key: i,
            SharesType: x.SharesType,
            VehicleType: x.VehicleType
          }
          arraySumaPorInversor.push(el)
        }
        else {
          arraySumaPorInversor[existe].Commitment = arraySumaPorInversor[existe].Commitment + x.Commitment;
          arraySumaPorInversor[existe].Amount = arraySumaPorInversor[existe].Amount + x.Amount;
          arraySumaPorInversor[existe].Capital_Gain = arraySumaPorInversor[existe].Capital_Gain + x.CapGain_Buyback2;
          arraySumaPorInversor[existe].Shares = arraySumaPorInversor[existe].Shares + x.Shares;
        }

        //cargar array de grupos
        let existeGrupo = arrayGrupos.map((e: any) => { return `${e.Colocador}-${e.Fondo}` })
          .indexOf(`${x.Colocador}-${x.IdPeip}`);
        if (existeGrupo == -1) {
          let el = {
            Colocador: x.Colocador,
            Fondo: x.PeipVehicle,
            Commitment: x.Commitment,
            Amount: x.Amount,
            Capital_Gain: x.Capital_Gain,
            Shares: x.Shares,
          }
          arrayGrupos.push(el);
        }
        else {

          arrayGrupos[existeGrupo].Commitment += x.Commitment
          arrayGrupos[existeGrupo].Amount += x.Amount
          arrayGrupos[existeGrupo].Capital_Gain += x.CapGain_Buyback2
          arrayGrupos[existeGrupo].Shares += x.Shares
        }
        if (i + 1 === json.length) {
          setDataInvestors(arraySumaPorInversor);
          props.setInfoInversores(arraySumaPorInversor);
          props.setInfoGrupos(arrayGrupos)
          setGrupos(arrayGrupos);
          armarGrid((props.fondoSeleccionado), "", arrayGrupos, arraySumaPorInversor);
        }
      })
  }

  const filtrarGrid = (e: string) => {
    let arrayFiltered = []
    if (e === "") {
      arrayFiltered = dataInvestors;
    }
    else {
      arrayFiltered = dataInvestors.filter((x: any) => x.institution
        .toLowerCase().includes(e.toLowerCase()))
    }
    armarGrid((props.fondoSeleccionado), e, grupos, arrayFiltered);
  }

  const armarGrid = (PeipVehicle: string, textoFiltro: string, grupos: any, dataInvestors: any) => {
    let arrayTemp: any = [];
    let arrayChildTemp: any = [];
    let totalArray: any = [{
      Commitment: 0,
      Amount: 0,
      Capital_Gain: 0,
      Shares: 0
    }];
    let el: any = {};
    el.expandAction = "click";
    
    const SCRType =  dataInvestors.filter((inv:any) => inv.Fondo === PeipVehicle && inv.VehicleType === 'SCR').length > 0;
    const typeTitle = SCRType ? props.traducciones.acciones : props.traducciones.participaciones ;
    el.title =
      <Row className="fila-tabla"  >        
        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="celda-nombre"><b>{props.traducciones.seleccionarTodos}</b></Col>
        <Col xs={0} sm={0} md={4} lg={4} xl={4} className="celda"><b>{props.traducciones.compromiso}</b></Col>
        <Col xs={0} sm={0} md={4} lg={4} xl={4} className="celda"><b>{props.traducciones.contribuciones}</b></Col>
        <Col xs={0} sm={0} md={4} lg={4} xl={4} className="celda"><b>{props.traducciones.distribuciones}</b></Col>
        <Col xs={0} sm={0} md={4} lg={4} xl={4} className="celda" title={`${typeTitle}`}><b>{typeTitle}</b></Col>
      </Row>;
    el.key = `Grupo-0`;
    el.Colocador = "Col";
    arrayChildTemp = [];
    dataInvestors.map((inv: any, childIndex: number) => {
      
      if (inv.Fondo === PeipVehicle) {
        let children: any = {};
        children.title =
          <Row className="fila-tabla">
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="celda-child-nombre" title={`${inv.institution} (${inv.SharesType})`}>{inv.institution} ({inv.SharesType})</Col>
            <Col xs={0} sm={0} md={4} lg={4} xl={4} className="celda-child">{String.currencyFormatByLanguage(inv.Commitment)}</Col>
            <Col xs={0} sm={0} md={4} lg={4} xl={4} className="celda-child">{String.currencyFormatByLanguage(inv.Amount)}</Col>
            <Col xs={0} sm={0} md={4} lg={4} xl={4} className="celda-child">{String.currencyFormatByLanguage(inv.Capital_Gain)}</Col>
            <Col xs={0} sm={0} md={4} lg={4} xl={4} className="celda-child">{String.currencyFormatByLanguage(inv.Shares)}</Col>
          </Row>;
        children.key = inv.idInvestor;
        children.tipo = "hijo";
        children.Colocador = inv.institution;
        arrayChildTemp.push(children);
        totalArray[0].Commitment += inv.Commitment;
        totalArray[0].Amount += inv.Amount;
        totalArray[0].Capital_Gain += inv.Capital_Gain;
        totalArray[0].Shares += inv.Shares;
      }
      if (childIndex + 1 === dataInvestors.length) {
        el.children = arrayChildTemp;
        //if (el.children.length > 0) {
        arrayTemp.push(el);

        // }
        setDataSourceTree(arrayTemp);
        setLoading(false);
        setTotalGrid(totalArray);
        props.setCompromiso(totalArray[0].Commitment)
        props.setContribuciones(totalArray[0].Amount)
        props.setDistribuciones(totalArray[0].Capital_Gain)
      }
    })

  }


  const onExpand = (expandedKeys: any) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false)
  };

  const onCheck = (checkedKeys: any, info: any) => {
    setCheckedKeys(checkedKeys);
    let sumaCompromiso = 0;
    let sumaContribuciones = 0;
    let sumaDistribucuiones = 0;
    checkedKeys.map((key: any, indexKey: number) => {

      props.InfoInversores.map((child: any, indexRow: number) => {
        if (parseInt(key) === parseInt(child.idInvestor)) {
          sumaCompromiso = sumaCompromiso + child.Commitment;
          sumaContribuciones = sumaContribuciones + child.Amount;
          sumaDistribucuiones = sumaDistribucuiones + child.Capital_Gain;
        }
        if (indexKey + 1 === checkedKeys.length && indexRow + 1 === props.InfoInversores.length) {
          props.setCompromiso(sumaCompromiso);
          props.setContribuciones(sumaContribuciones);
          props.setDistribuciones(sumaDistribucuiones);
        }
      });
    });
    let arrayCheckedKeys: any = checkedKeys;
    if (checkedKeys.length === 0) {
      props.setselectedRowKeys([]);
      props.InfoInversores.filter((x: any) => x.Fondo === (props.fondoSeleccionado))
        .map((child: any, indexRow: number) => {
          sumaCompromiso = sumaCompromiso + child.Commitment;
          sumaContribuciones = sumaContribuciones + child.Amount;
          sumaDistribucuiones = sumaDistribucuiones + child.Capital_Gain;
          if (indexRow + 1 === props.InfoInversores.filter((x: any) => x.Fondo === (props.fondoSeleccionado)).length) {
            props.setCompromiso(sumaCompromiso);
            props.setContribuciones(sumaContribuciones);
            props.setDistribuciones(sumaDistribucuiones);

          }
        });
    }
    props.setselectedRowKeys(arrayCheckedKeys);
    info.checkedNodes.map((nodo: any, i: any) => {
      props.setselectedRowKeys(arrayCheckedKeys);
      if (nodo.props.tipo === undefined) {
        arrayCheckedKeys = arrayCheckedKeys.filter((x: any) => x !== nodo.key);
      }
      if (i + 1 === info.checkedNodes.length) {
        props.setselectedRowKeys(arrayCheckedKeys);
      }
    });

  }

  const onSelect = (selectedKeys: any, info: any) => {
    setSelectedKeys(selectedKeys)
  };

  return (
    <div className={`${styles.investorsTable}`}>
      {Loading ?
        <div className='contenedorSpiner'>
          <Spin /> <br>
          </br>
          <h2>{props.traducciones.cargando}</h2>
        </div>
        :
        <>
          <Row>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <div className="titulo-tab" style={{ textAlign: 'right', marginRight: 10 }}>
                {props.traducciones.buscar}
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <Search
                placeholder={props.traducciones.busquedaHolder}
                onSearch={value => filtrarGrid(value)}
                style={{ width: '100%' }}
              />
            </Col>
            <Col xs={0} sm={0} md={7} lg={7} xl={7}></Col>
            <Col xs={0} sm={0} md={4} lg={4} xl={4}>
              <Button type="primary" className="boton" onClick={() => props.setVisible(true)}>{props.traducciones.descargarExcel}</Button>
            </Col> 
          </Row>

          <Tree
            checkable
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            onSelect={onSelect}
            selectedKeys={selectedKeys}
            treeData={dataSourceTree}
          />
          <Row className="fila-encabezado-table" style={{ marginTop: 0 }}>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} style={{ textAlign: 'center' }}>
              TOTAL
            </Col>
            <Col xs={0} sm={0} md={4} lg={4} xl={4} style={{ textAlign: 'center', paddingLeft: '30px' }}>
              {totalGrid.length > 0 && String.currencyFormatByLanguage(totalGrid[0].Commitment)}
            </Col>
            <Col xs={0} sm={0} md={4} lg={4} xl={4} style={{ textAlign: 'center',paddingLeft: '5px'  }}>
              {totalGrid.length > 0 && String.currencyFormatByLanguage(totalGrid[0].Amount)}
            </Col>
            <Col xs={0} sm={0} md={4} lg={4} xl={4} style={{ textAlign: 'center', paddingRight:'15px' }}>
              {totalGrid.length > 0 && String.currencyFormatByLanguage(totalGrid[0].Capital_Gain)}
            </Col>
            <Col xs={0} sm={0} md={3} lg={3} xl={3} style={{ textAlign: 'center', paddingLeft:'20px'}}>
              {totalGrid.length > 0 && String.currencyFormatByLanguage(totalGrid[0].Shares)}
            </Col>
            <Col xs={0} sm={0} md={1} lg={1} xl={1} >
            </Col>
          </Row>
        </>
      }
    </div>
  );
};

export default InvestorsTable;
