import React, { useState, useEffect } from 'react';
import {
    Form, Icon, Input, Button, Checkbox, Row, Col, message, Spin, Modal
} from 'antd';
import './Login.css';
import logo from '../../Assets/images/logo.png';
import { loginService } from '../../Servicios/login.service';
import { ILogin, IRecuperarPassword } from '../../Modelos/inversores.models';
import { Utils } from '../../utils/Utils';
import { IaccessLog } from '../../Modelos/AccessLog.model';
import LogAPI from '../../Api/api.log';
import {
    getLanguage,
    dictionaryEmail,
    dictionaryPass,
    dictionaryForgotPass,
    dictionaryLogin
} from "./dictionary";
const _LOGIN = new loginService();
const _RECUPERARPASSWORD = new loginService();
const { confirm } = Modal;

const LoginUsuario = (props: any) => {

    const [correo, setCorreo] = useState("");
    const [pass, setPass] = useState("");
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [language, setLanguage] = useState<string>("en");

    useEffect(() => {
        setLanguage(getLanguage[window.location.pathname.split("/")[1]]());

        console.log(window.navigator.language)
        /*if(window.location.origin !== "https://portalinversores.qualitasfunds.eu" )
        {
           window.location.href = window.location.pathname === 'DataRoom' ? "https://portalinversores.qualitasfunds.eu/login/DataRoom" : "https://portalinversores.qualitasfunds.eu/login/PortalInversor";
        }
        let token = Utils.obtenerCookie("token");
        if(token !== "")
        {
            let userData = Utils.parseJwt(token);
            const now = Date.now().valueOf() / 1000;
            if (typeof userData.exp !== 'undefined' && userData.exp > now) {
                window.top.location.href = window.location.pathname === 'DataRoom' ? "https://portalinversores.qualitasfunds.eu/DataRoom" : "https://portalinversores.qualitasfunds.eu";
              }
        }*/
    }, [])

    const generate_token = (length: number) => {
        var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
        var b = [];
        for (var i = 0; i < length; i++) {
            var j: any = (Math.random() * (a.length - 1)).toFixed(0);
            b[i] = a[j];
        }
        return b.join("");
    }

    const recuperarPass_ = () => { 
        if (correo !== "") {
            setLoading(true)
            let data: IRecuperarPassword = {
                loginName: correo,
                token: generate_token(20),
                redirectURL: window.location.origin
            }
            _RECUPERARPASSWORD.recuperarPassword(data).then((respuesta: any) => {
                if (respuesta.result) {
                    message.success(`Se enviará un email a ${correo} para restaurar su contraseña`)
                    setLoading(false)
                }
                else {
                    message.error("Se ha producido un error, por favor intente de nuevo")
                    setLoading(false);
                }
            });
        }
        else {
            message.warning("Indica cual es tu correo electrónico");
        }
    }

    const registrarAccesso = async (documentName: string, url: string, movement: string, email: string, token: string) => {
        let data: IaccessLog = {
            documentName: documentName,
            url: url,
            date: new Date(),
            movement: movement,
            email: email
        }
        LogAPI.AccessLog(data).then(json => {
            //let lang = document.referrer === 'https://qualitasfunds.com/' ? 'es' : 'en'
            let lang = navigator.language.split("-")[0];
            if(window.top !== null){
                window.top.location.href = '/SessionCookieGenerator/' + token + '/' + props.match.params.app + '/' + lang;
            }
            /*if (props.match.params.app === "PortalInversor") {
                   window.top.location.href = 'https://portalinversores.qualitasfunds.eu/';
               }
               else {
                   window.top.location.href = 'https://portalinversores.qualitasfunds.eu/DataRoom';
               }*/
        })
            .catch(err => console.log(err));
    }

    const iniciarSesion = () => {

        setLoading(true)
        let data: ILogin = {
            loginName: correo,
            password: pass,
            system: props.match.params.app //PortalInversor - DataRoom
        }

        message.config({
            duration: 6,
        });
        _LOGIN.login(data).then((respuesta: any) => {
            setLoading(false);
            const log = () =>{
                let userData = Utils.parseJwt(respuesta.result.token);
                Utils.crearCookie("token", respuesta.result.token, userData.exp);
                localStorage.token = respuesta.result.token;
                //localStorage.idioma = document.referrer;
                registrarAccesso("", `/${props.match.params.app}`, 'login', userData.unique_name, respuesta.result.token);
            } 
            if (respuesta.result.Credentials && respuesta.result.Access) {
                if (respuesta.maintenance == true) {
                    if (respuesta.emailMaintenance == data.loginName) {
                        log();
                    } else {
                        window.location.href = '/maintenance';
                    }
                }else{
                    log();
                }
            }
            else {
                if (respuesta.result.Credentials && !respuesta.result.Access) {
                    message.error("No tiene acceso al sistema")
                }
                else {
                    message.error("Usuario o Contraseña inválidos")
                }
            }

        })

    }

    const inputStyle = {
        ':hover': {
            borderColor: '#001435'
        }
    };

    return (

        <div className='fondoLogin'>

            <Row>
                <Col xs={1} sm={1} md={8} lg={8} xl={8}>

                </Col>

                <Col xs={21} sm={21} md={7} lg={7} xl={7} className='contendorLogin'>
                    <Row>
                        <Col xs={0} sm={0} md={8} lg={8} xl={8}>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            {<img src={logo} className='logoInversores' style={{ margin: '7px', marginBottom: '20px' }} />}
                        </Col>
                        <Col xs={0} sm={0} md={8} lg={8} xl={8}>
                        </Col>
                    </Row>

                    <Form className="loginForm">
                        <Form.Item>
                            <Input
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                style={{ height: 40 }}
                                //placeholder={
                                  //  window.navigator.language == "es" ? 'Correo' : 'Email'
                                //}
                                placeholder={dictionaryEmail[language]}
                                value={correo}
                                type="email"
                                onChange={(e) => setCorreo(e.target.value)}
                            /> {loading ?
                                <div className='contenedorSpiner'>
                                    <Spin /> <br>
                                    </br>
                                    Cargando...
                                </div>
                                : null
                            }
                        </Form.Item>

                        <Form.Item>
                            <Input.Password
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                //placeholder={
                                //    window.navigator.language == "es" ? 'Contraseña' : 'Password'
                                //}
                                placeholder={dictionaryPass[language]}
                                style={{ height: 40 }}
                                value={pass}
                                onChange={(e) => setPass(e.target.value)}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Row>
                                <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                                   <a className="ResetPassword" onClick={() => setModalVisible(true)}>
                                   {
                                         //window.navigator.language == "es" ? 'Recuperar contraseña' : 'Forgot Password'
                                         dictionaryForgotPass[language]
                                        }
                                </a> 
                                </Col>
                                <Col xs={10} sm={10} md={18} lg={10} xl={10}>
                                    <Button type="primary" htmlType="submit" className="botonLogin" onClick={iniciarSesion}>
                                    {
                                            dictionaryLogin[language]
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </Col>
                <Col xs={1} sm={1} md={8} lg={8} xl={8}>
                </Col>
            </Row>
            <Modal
                style={{ top: 0 }}
                visible={modalVisible}
                onOk={recuperarPass_}
                onCancel={() => setModalVisible(false)}
                footer={[
                    <Button key="back" loading={loading} onClick={() => setModalVisible(false)}>
                        Cancelar
                    </Button>,
                    <Button loading={loading} key="submit" type="primary" onClick={recuperarPass_}>
                        ok
                    </Button>,
                ]}
            >
                Escriba su email:
                <Input
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    style={{ height: 40 }}
                    placeholder="Correo"
                    value={correo}
                    type="email"
                    onChange={(e) => setCorreo(e.target.value)}
                />
            </Modal>
        </div>
    );
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(LoginUsuario);

export default LoginUsuario;