import React from 'react';
import './index.css'


const Maintenance = () =>{
    return(
        <div className='container'>
            <img src={require("../../Assets/images/logo.png")} style={{ width: 350 }} />
            <h1> Portal Inversor en Mantenimiento </h1>
        </div>
    )
}

export default Maintenance;