import axios from 'axios';
import {Utils} from '../utils/Utils';
import { IPeips } from '../Modelos/inversores.models';

export class menuServices {
    public obtenerPeips(IdContact:number):Promise<IPeips[]>{
        return new Promise((resolve,reject)=>{
            try {
                Utils.getConfig().then((config: any) => {
                    axios.get(`${config.data[0].url_inversores}investors/getlistpeipcontact/${IdContact}`)
                        .then((data) => {
                            if (data !== null) {
                                return resolve(data.data);
                            }
                            else {
                                return reject(null);
                            }
                        })
                });
            } catch (error) {
                return reject(null);
            }
        });
    }

}