import React, {useEffect} from 'react';
import { ISessionCookieGeneratorProps } from './ISessionCookieGeneratorProps';
import styles from './SessionCookieGenerator.module.scss';
import { Utils } from '../../utils/Utils';

const SessionCookieGenerator = (props:any) => {

  useEffect(() => {
    if(props.match.params.token !== undefined)
    {
      let userData = Utils.parseJwt(props.match.params.token);
      Utils.crearCookie("token",props.match.params.token,userData.exp);
      localStorage.token = props.match.params.token;
      localStorage.idioma = props.match.params.lang;
      if(props.match.params.app == 'DataRoom')
      {
        window.location.href = 'https://qenergyportalinversores.azurewebsites.net/' + props.match.params.app;
      }
      else{
        window.location.href = '/';
      }
    }
    else{
      window.location.href = "/login/PortalInversor";
    }
    
  }, []);
  return (
    <div className={`${styles.sessionCookieGenerator}`}>
      <h3>Generando Session de usuario...</h3>
    </div>
  );
};

export default SessionCookieGenerator;
