import React, { useState, useEffect } from 'react';
import './Header.css';
import { Row, Col } from 'antd';
import { Icon } from '@ant-design/compatible'
import ModalVideo from '../ModalVideo/ModalVideo';
import { documentosService } from '../../Servicios/documentos.service';
const _DESCARGARDOCUMENTOS = new documentosService();

const Header = (props: any) => {

    const [tabInversores, settabInversores] = useState("tabSeleccionado");
    const [tabDocumentos, settabDocumentos] = useState("tab");
    const [tabAnaliza, settabAnaliza] = useState("tab");
    const [selectedSection, setSelectedSection] = useState("Inversores");
    const [modalVideoVisible, setModalVideoVisible] = useState(false);
    const [url, setUrl] = useState("");

    const cambiarTab = (tab: any) => {
        settabInversores("tab");
        settabDocumentos("tab");
        settabAnaliza("tab");
        if (tab === "Inversores") {
            settabInversores("tabSeleccionado");
        }
        if (tab === "Documentos") {
            settabDocumentos("tabSeleccionado");
        }
        if (tab === "Analiza tu inversión") {
            settabAnaliza("tabSeleccionado");
        }
        props.setTabSeleccionado(tab);
    }

    const handleClickMenu = (e: any) => {
        console.log('click ', e);
    };

    const changeSelectedSection = (section: string) => {
        setSelectedSection(section);
        cambiarTab(section)
    }

    const handleLanguageChange = (e: any) => {
        props.setIdioma(e);
    }

    const getBlobDocument = (url: string) => {
        _DESCARGARDOCUMENTOS.GetBlobStorageDocument(url).then(res => {
            window.location = res.replaceAll("​", "");
        })
    }


    return (
        <div>
            <div className="contenedor-header">
                <Row className="contenedorHeader">
                    <Col xs={8} sm={8} md={2} lg={2} xl={2}></Col>
                    <Col xs={8} sm={8} md={2} lg={2} xl={2}>
                        <img src={require("../../Assets/images/logo.png")} />
                        {window.location.origin === "https://qenergyportalinversores.azurewebsites.net/" &&
                            <h1 style={{ color: 'red' }}>Quality Assurance</h1>
                        }
                        {window.location.origin === "http://localhost:3001" &&
                            <h1 style={{ color: 'red' }}>Develop</h1>
                        }
                    </Col>
                    <Col xs={8} sm={8} md={6} lg={6} xl={6}></Col>
                    <Col xs={24} sm={24} md={3} lg={3} xl={3}
                        className={`textMenu ${selectedSection === "Inversores" && "textMenuActive"}`}
                        onClick={() => changeSelectedSection("Inversores")}>
                        {props.traducciones.inversores}
                    </Col>
                    <Col xs={24} sm={24} md={3} lg={3} xl={3}
                        className={`textMenu ${selectedSection === "Documentos" && "textMenuActive"}`}
                        onClick={() => changeSelectedSection("Documentos")}>
                        {props.traducciones.documentos}
                    </Col>
                    {/*   <Col xs={24} sm={24} md={4} lg={4} xl={4}
                        className={`textMenu ${selectedSection === "analisis" && "textMenuActive"}`}
                        onClick={() => changeSelectedSection("Analiza tu inversión")}>
                        {props.traducciones.analiza}
                    </Col>  */}
                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                        <div className="btnVideo" onClick={() => setModalVideoVisible(true)}><Icon type="PlayCircle" />&nbsp;Tutorial</div>
                    </Col>
                </Row>

                <ModalVideo
                    modalVideoVisible={modalVideoVisible}
                    setModalVideoVisible={setModalVideoVisible}
                    url={props.idioma == 'es' ? 'https://player.vimeo.com/video/640021587' : 'https://player.vimeo.com/video/640021322'}
                />
            </div>


        </div>
    );
};


export default Header;
