import axios from 'axios';
import {Utils} from '../utils/Utils';

export class institutionServices {
    public getInstitutionsList(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            try {
               Utils.getConfig().then((config: any) => {
                    axios.get(`${config.data[0].url_inversores}/access/institutions`)
                        .then((data:any) => {
                            if (data !== null) {
                                return resolve(data.data.result);
                            }
                            else {
                                return reject(null);
                            }
                        })
                });
            } catch (error) {
                return reject(null);
            }
        })
    }

    public getContactInstitutionsList(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            try {
               Utils.getConfig().then((config: any) => {
                    axios.get(`${config.data[0].url_inversores}/access/contact_institutions`)
                        .then((data:any) => {
                            if (data !== null) {
                                return resolve(data.data.result);
                            }
                            else {
                                return reject(null);
                            }
                        })
                });
            } catch (error) {
                return reject(null);
            }
        })
    }

}