import React, { useState, useEffect } from 'react';
import { IModalPDFViewerProps } from './IModalPDFViewerProps';
import { Row, Spin, Col, Modal, Button, Icon, message } from 'antd';
import PDFViewer from 'pdf-viewer-reactjs';

const ModalPDFViewer: React.SFC<IModalPDFViewerProps> = (props) => {

  useEffect(() => {
  }, [props.tokenDocumento]);

  return (
    <Modal
      style={{ top: 5, textAlign: 'center' }}
      visible={props.modalVisorPDFVisible}
      title={props.nombreDocumento}
      width={"100%"}
      onOk={() => props.setModalVisorPDFVisible(false)}
      onCancel={() => props.setModalVisorPDFVisible(false)}
      footer={[

      ]}
    >
      {props.fullAccess ?
        <object data={`${props.tokenDocumento}#status=1&menubar=1&toolbar=1&location=1&resizable=1&scrollbars=1`} type="application/pdf" width="100%" height="500px">
          <param name="src" value={`${props.tokenDocumento}#status=1&menubar=1&toolbar=1&location=1&resizable=1&scrollbars=1`} />
        </object>
        :
        <PDFViewer
          document={{ url: props.tokenDocumento }}

          protectContent={true}
          navbarOnTop={true}
          hideRotation={true}
          scale={1.5}
        />
      }
    </Modal>
  );
};

export default ModalPDFViewer;
