import axios from 'axios';
import { Utils } from '../utils/Utils';
export class MaintenanceService {
    public obtenerValorGenericaMantenimiento(props:any): Promise<any> {
        const { code } = props;
        return new Promise((resolve, reject) => {
            try {
                Utils.getConfig().then((config:any)  => {
                    axios.get(config.data[0].url_inversores+'generic/getgeneric?code='+code)
                    .then((data) => {
                        if (data !== null) {
                            return resolve(data.data);
                        }
                        else {
                            return reject(null);
                        }
                    });
                });
            }catch (error) {
                return reject(null);
            }
        });
    } 
}