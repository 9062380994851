import axios from 'axios';
import {Utils} from '../utils/Utils';


export class reportesService {
    public obtenerReportes(name:string,url:string,parameters:string):Promise<any[]>{
        return new Promise((resolve,reject)=>{
            try {
                Utils.getConfig().then((config: any) => {
                    axios.get(`${config.data[0].url_inversores}/reports/getreport?name=${name}&url=${url}&parameters=${parameters}`)
                    .then((data) => { 
                        if (data.data.code == 200) {
                            return resolve(data.data.result);
                        }
                        else {
                            return reject(null)
                        }
                    })
                   // window.location.href=`${config.data[0].url_inversores}/reports/getreport?name=${name}&url=${url}&parameters=${parameters}`;
                }); 
            } catch (error) {
                return reject(null);
            }
        });
    }

    public descargarExcel(name:string,url:string, Investor:string):Promise<any[]>{
        return new Promise((resolve,reject)=>{
            try {
                Utils.getConfig().then((config: any) => {
                    window.location.href=`${config.data[0].url_inversores}/reports/getexcelpivot?name=${name}&url=${url}&IdContact=${Investor}`;
                }); 
            } catch (error) {
                return reject(null);
            }
        });
    }

    public tiposReporte():Promise<any[]>{
        return new Promise((resolve,reject)=>{
            try {
                Utils.getConfig().then((config: any) => {
                    axios.get(`${config.data[0].url_inversores}reports/getlistreport`)
               
                        .then((data) => { 
                            if (data !== null) {
                                return resolve(data.data.result);
                            }
                            else {
                                return reject(null);
                            }
                        })
                });
            } catch (error) {
                return reject(null);
            }
        });
    }

    public listarparametros(idReport:number):Promise<any[]>{
        return new Promise((resolve,reject)=>{
            try {
                Utils.getConfig().then((config: any) => {
                    axios.get(`${config.data[0].url_inversores}/reports/getdetailtreport?idReport=${idReport}`)
               
                        .then((data) => { 
                            if (data !== null) {
                                return resolve(data.data.result);
                            }
                            else {
                                return reject(null);
                            }
                        })
                });
            } catch (error) {
                return reject(null);
            }
        });
    }

}