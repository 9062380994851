import React, { useState, useEffect } from 'react';
import axios from 'axios';


const CrearUsuarios = ({ }) => {

    const [usuariosGuardados, setusuariosGuardados] = useState([]);

    useEffect(() => {
        
        recorrerArreglo();
       
    }, []);

    const recorrerArreglo = () => {
        /*list.map(x=>{
            //crearUsuario(x.Email,x.Password,x.IdContact)
        });*/
    }

    const crearUsuario = (email,pass,idContact) => {
        axios.post(`http://localhost:57042/api/users/postcreateuserstemp?loginName=${email}&password=${pass}&idContact=${idContact}`)
            .then((data) => {
                if (data !== null) {
                    console.log(data.data.result);
                }
            })
    }

    return (
        <div>
            <h1>CREAR USUARIOS TEMPORAL</h1>
        </div>
    );
};

export default CrearUsuarios;
