import axios from 'axios';
import {Utils} from '../utils/Utils';
import { IaccessLog } from '../Modelos/AccessLog.model';

const LogAPI = {
    AccessLog(data:IaccessLog) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.post(`${config.data[0].url_inversores}/AccessLog`,data)
                    .then((data) => 
                    { 
                        if (data !== null) {
                            return resolve(data.data);
                        }
                        else {
                            return reject(null);
                        }
                    })
            });
        });
    }
}

export default LogAPI;