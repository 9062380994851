import axios from 'axios';
import {Utils} from '../utils/Utils';

export class inversoresService {
    public obtenerInversores(IdPeip:number,startDate:string, endDate:string, login:String): Promise<any[]> {
        return new Promise((resolve, reject) => {
            try {
               Utils.getConfig().then((config: any) => {
                    axios.get(`${config.data[0].url_inversores}/investors/getinformation?idPeip=${IdPeip}&startDate=${startDate}&endDate=${endDate}&loginName=${login}`)
                        .then((data:any) => {
                            if (data !== null) {
                                return resolve(data.data.result);
                            }
                            else {
                                return reject(null);
                            }
                        })
                });
            } catch (error) {
                return reject(null);
            }
        })
    }

    public obtenerListaRelaciones(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            try {
               Utils.getConfig().then((config: any) => {
                    axios.get(`${config.data[0].url_inversores}/access/adminUsers`)
                        .then((data:any) => {
                            if (data !== null) {
                                return resolve(data.data.result);
                            }
                            else {
                                return reject(null);
                            }
                        })
                });
            } catch (error) {
                return reject(null);
            }
        })
    }

    public getContactList(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            try {
               Utils.getConfig().then((config: any) => {
                    axios.get(`${config.data[0].url_inversores}/contact/getcontactlist`)
                        .then((data:any) => {
                            if (data !== null) {
                                return resolve(data.data.result);
                            }
                            else {
                                return reject(null);
                            }
                        })
                });
            } catch (error) {
                return reject(null);
            }
        })
    }

    public addContactInvestor(idInvestor:number, idContact:number): Promise<any[]> {
        return new Promise((resolve, reject) => {
            try {
               Utils.getConfig().then((config: any) => {
                    axios.post(`${config.data[0].url_inversores}/contact/postcontactInvestor?IdContact=${idContact}&IdInvestor=${idInvestor}`)
                        .then((data:any) => {
                            if (data !== null) {
                                return resolve(data.data.result);
                            }
                            else {
                                return reject(null);
                            }
                        })
                });
            } catch (error) {
                return reject(null);
            }
        })
    }

}
